/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/authentication';
//
import { firebaseConfig } from '../config';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['roncohen04@gmail.com'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  //firebase.firestore.setLogLevel("debug");
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<firebase.firestore.DocumentData | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        if (user) {
          firebase.auth().currentUser.getIdToken().then((token) => console.debug("Temp - my token: ", token))

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithMicrosoft = () => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithGithub = () => {
    const provider = new firebase.auth.GithubAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('Users')
          .doc(res.user?.email)
          .set({
            CreatedAt: firebase.firestore.Timestamp.now(),
            UpdatedAt: firebase.firestore.Timestamp.now(),
            Email: res.user?.email,
            Name: !firstName && !lastName ? '' : `${firstName} ${lastName}`,
            PhotoUrl: '',
            Status: 'Active',
            AuthenticationType: 'UserPassword'
          });
      });

  const logout = async () => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    await firebase.auth().signOut();
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const sendVerificationCode = (onVerificationSend: () => void) => {
    firebase.auth().currentUser.sendEmailVerification()
      .then(onVerificationSend)
  }

  const auth = { ...state.user };

  const db = firebase.firestore();

  useEffect(() => {
    const settings: firebase.firestore.Settings = {
      experimentalAutoDetectLongPolling: true
    }
    console.log("Using custom settings for firebase: ", settings);
    db.settings(settings);
  }, []);

  const getIdToken = async () => {
    return firebase.auth().currentUser.getIdToken()
  };

  const isEmailVerified = () => {
    if (!!auth?.providerData && auth.providerData.length > 0 && !!auth.providerData[0]?.providerId) {
      const providerId: string = auth.providerData[0].providerId;
      return providerId.toLowerCase() !== 'password' || (auth?.emailVerified ?? false);
    }

    return false;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          emailVerified: isEmailVerified(),
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(auth.email) ? 'admin' : 'user',
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
        },
        db,
        login,
        register,
        loginWithGoogle,
        loginWithMicrosoft,
        loginWithGithub,
        logout,
        resetPassword,
        sendVerificationCode,
        updateProfile: () => { },
        getIdToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
