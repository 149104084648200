// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Icon } from '@iconify/react';

export type FeatureInfo = {
  title: string;
  path: string;
  icon: JSX.Element;
  public: boolean;
}

const FeaturesInfo = {
  Assistant: {
    title: 'Assistant',
    path: PATH_DASHBOARD.general.assistant,
    public: false,
    icon: <Icon icon="material-symbols:memory" width='100%' height='100%' />
  } as FeatureInfo,
  DataExplorer: {
    title: 'Data Explorer',
    path: PATH_DASHBOARD.general.users,
    public: true,
    icon: <Icon icon="material-symbols:query-stats-rounded" width='100%' height='100%' />
  } as FeatureInfo,
  DataConnections: {
    title: 'Data Connections',
    path: PATH_DASHBOARD.general.connections,
    public: true,
    icon: <Icon icon="icon-park-solid:six-circular-connection" width='100%' height='100%' />
  } as FeatureInfo,
  Ingestion: {
    title: 'Ingestion',
    path: PATH_DASHBOARD.general.ingestion,
    public: true,
    icon: <Icon icon="material-symbols:webhook" width='100%' height='100%' />
  } as FeatureInfo,
  TablesManagement: {
    title: 'Tables',
    path: PATH_DASHBOARD.general.tables,
    public: true,
    icon: <Icon icon="material-symbols:table-view" width='100%' height='100%' />
  } as FeatureInfo,
  QueriesManagement: {
    title: 'Queries',
    path: PATH_DASHBOARD.general.queries,
    public: true,
    icon: <Icon icon="material-symbols:home-storage-rounded" width='100%' height='100%' />
  } as FeatureInfo,
  Settings: {
    title: 'Settings',
    path: PATH_DASHBOARD.general.settings,
    public: true,
    icon: <Icon icon="material-symbols:settings" width='100%' height='100%' />
  } as FeatureInfo
}

export function getFeaturesInfoArray(): FeatureInfo[] {
  let result: FeatureInfo[] = [];
  for (let feature in FeaturesInfo) {
    result.push(FeaturesInfo[feature]);
  }

  return result;
}

export default FeaturesInfo;
