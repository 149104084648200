import { useDropzone, DropzoneOptions } from 'react-dropzone';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Theme, Typography, Stack, Card } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
// utils
//
import { UploadIllustration } from '../../../../assets';
import { formatBytes } from '../helper_functions';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer'
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

interface UploadSingleFileProps extends DropzoneOptions {
  disabled?: boolean;
  error?: boolean;
  file: File;
  sx?: SxProps<Theme>;
}

export default function UploadSingleFile({ disabled = false, error = false, file, sx, ...other }: UploadSingleFileProps) {

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ multiple: false, ...other });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file && { padding: '12% 0' })
        }}
      >
        <input {...getInputProps()} />
        {!file &&
          <>
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3, ml: { md: 2 } }}>
              <Typography gutterBottom variant="h5">
                Click or Drag a file to here.
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Select a Json, csv or Excel (xlsx) file to ingest into Pipebase.io.
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Need support for new format?
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Great just send us a feedback
              </Typography>
            </Box>
          </>
        }

        {file && (
          <Stack width={1} spacing={1} display='flex' alignItems='center' justifyItems='center' >
            <Card sx={{ width: 0.5, height: 0.3, p: 2 }} >
              <Box
                component="img"
                alt="file preview"
                src='/static/faqs/ic_package.svg'
                sx={{
                  width: 1,
                  height: 1
                }}
              />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Name: {file.name}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Type: {file.type}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Size: {formatBytes(file.size)}</Typography>
            </Card>
          </Stack>
        )}
      </DropZoneStyle>
    </Box>
  );
}