export enum ColumnType {
    Unknown,
    String,
    Real,
    Long,
    DateTime,
    TimeSpan,
    Dynamic,
    Boolean
}

export class ExtendedColumnType {
    static readonly NumericalTypes = [ColumnType.DateTime, ColumnType.Long, ColumnType.Real, ColumnType.TimeSpan];
    static readonly NumberTypes = [ColumnType.Real, ColumnType.Long];
    static readonly TimeTypes = [ColumnType.DateTime, ColumnType.TimeSpan];

    static isTime(colType: ColumnType) {
        return this.TimeTypes.includes(colType);
    }

    static isNumber(colType: ColumnType) {
        return this.NumberTypes.includes(colType);
        return colType === ColumnType.Real || colType === ColumnType.Long;
    }

    static isNumerical(colType: ColumnType) {
        return this.NumericalTypes.includes(colType);
    }

    static Parse(typeStr: string) {
        let str = typeStr.toLowerCase();

        switch (str) {
            case "string": return ColumnType.String;
            case "guid": return ColumnType.String;
            case "real": return ColumnType.Real;
            case "double": return ColumnType.Real;
            case "float": return ColumnType.Real;
            case "long": return ColumnType.Long;
            case "int": return ColumnType.Long;
            case "integer": return ColumnType.Long;
            case "number": return ColumnType.Long;
            case "decimal": return ColumnType.Long;
            case "dynamic": return ColumnType.Dynamic;
            case "object": return ColumnType.Dynamic;
            case "array": return ColumnType.Dynamic;
            case "datetime": return ColumnType.DateTime;
            case "timespan": return ColumnType.TimeSpan;
            case "boolean": return ColumnType.Boolean;
            case "bool": return ColumnType.Boolean;
            default: return ColumnType.Unknown;
        }
    }

    static fromKqlType(kqlString: string): ColumnType {
        if (!kqlString) {
            return ColumnType.Unknown;
        }

        const kqlStringLower = kqlString.toLowerCase();
        switch (kqlStringLower) {
            case "string": return ColumnType.String;
            case "guid": return ColumnType.String;
            case "real": return ColumnType.Real;
            case "int": return ColumnType.Long;
            case "long": return ColumnType.Long;
            case "decimal": return ColumnType.Long;
            case "dynamic": return ColumnType.Dynamic;
            case "datetime": return ColumnType.DateTime;
            case "timespan": return ColumnType.TimeSpan;
            case "boolean": return ColumnType.Boolean;
            case "bool": return ColumnType.Boolean;
        }

        return ColumnType.Unknown;
    }

    static toKqlType(columnType: ColumnType): string {
        if (columnType === ColumnType.Unknown) {
            return 'object';
        }

        switch (columnType) {
            case ColumnType.String: return "string";
            case ColumnType.Real: return "real";
            case ColumnType.Long: return "long";
            case ColumnType.DateTime: return "datetime";
            case ColumnType.TimeSpan: return "timespan";
            case ColumnType.Dynamic: return "dynamic";
            case ColumnType.Boolean: return "bool";
        }

        return 'object';
    }
}