import { Link as RouterLink } from 'react-router-dom';
import { Card, Container, Stack, Divider, Box, Grid, Link } from '@material-ui/core';

// material
import { styled } from '@material-ui/core/styles';
import Logo from 'components/Logo';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// components
import Page from '../../components/Page';
// hooks
import useAuth from '../../hooks/useAuth';
import { LoginForm } from 'components/authentication/login';
import { Typography } from '@material-ui/core';
import { PATH_AUTH } from 'routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  return (
    <RootStyle title="Login">
      <Container maxWidth="sm">
        <ContentStyle>
          <Card sx={{ p: 2 }}>
            <Stack spacing={1} alignItems='center' justifyItems='center' sx={{ width: 1, mb: 2, mt: 1 }}>
              <Logo variant='logo-sideway' sx={{ width: 0.8 }} />
              <Typography width={1} textAlign='center' variant='subtitle1'>
                First time here?&nbsp;
                <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.register}>
                  Sign Up Now
                </Link>
              </Typography>
            </Stack>
            <LoginForm />
            <Grid container sx={{ my: 2 }} alignItems='center' justifyItems='center'>
              <Grid item xs={4} md={4} ><Divider /></Grid>
              <Grid item xs={4} md={4}>
                <Typography width={1} textAlign='center' variant='subtitle2'> Single sign on</Typography>
              </Grid>
              <Grid item xs={4} md={4}><Divider /></Grid>
            </Grid>
            <AuthFirebaseSocials />
          </Card >
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
