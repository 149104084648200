import { Icon } from '@iconify/react';
// material
import { Button, Stack, Typography } from '@material-ui/core';
import closeFill from '@iconify/icons-eva/close-fill';

// hooks
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack5';
import { MIconButton } from 'components/@material-extend';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { loginWithGoogle, loginWithMicrosoft, loginWithGithub } = useAuth();

  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle?.();
    } catch (error) {
      handleLoginError('Google', error);
    }
  };

  const handleLoginMicrosoft = async () => {
    try {
      await loginWithMicrosoft?.();
    } catch (error) {
      handleLoginError('Microsoft', error);
    }
  };

  const handleLoginGithub = async () => {
    try {
      await loginWithGithub?.();
    } catch (error) {
      handleLoginError('Github', error);
    }
  };

  const handleLoginError = (provider: string, error: any) => {
    console.error(error);
    enqueueSnackbar(`Failed to sign in with ${provider}, ${error?.message}`, {
      variant: 'error',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }

  return (
    <>
      <Stack spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginGoogle}
        >
          <Stack direction="row">
            <Icon icon='logos:google-icon' height={24} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              Sign in with Google
            </Typography>
          </Stack>
        </Button>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginMicrosoft}
        >
          <Stack direction="row">
            <Icon icon='logos:microsoft-icon' height={24} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              Sign in with Microsoft
            </Typography>
          </Stack>
        </Button>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginGithub}
        >
          <Stack direction="row">
            <Icon icon='logos:github-icon' height={24} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              Sign in with Github
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </>
  );
}
