// material
import { Box, BoxProps } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export type LogoVariant = 'logo' | 'icon' | 'logo-sideway';

export interface LogoProps {
  sx?: any;
  variant: LogoVariant;
}

export default function Logo({ sx = {}, variant = 'logo' }: LogoProps) {
  const theme = useTheme();
  //const PRIMARY_LIGHT = theme.palette.primary.light;
  //const PRIMARY_MAIN = theme.palette.primary.main;
  //const PRIMARY_DARK = theme.palette.primary.dark;

  const logoPath = `/static/pipebase-io/${variant}.svg`;

  return (
    <Box sx={{ ...sx }} maxWidth={1}>
      <img src={logoPath} alt="pipebase.io" />
    </Box >
  );
}
