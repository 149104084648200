import { Card, Container } from '@material-ui/core';

// material
import { styled } from '@material-ui/core/styles';
// components
import Page from 'components/Page';
// hooks
import WorkspaceCreationContent from 'components/pipebase-io/workspace-management/WorkspaceCreationContent';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function NewWorkspaceCreationPage() {
    return (
        <RootStyle title="Pipebase.io - workspace creation">
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                <ContentStyle>
                    <Card sx={{ p: 2 }}>
                        <WorkspaceCreationContent />
                    </Card >
                </ContentStyle>
            </Container>
        </RootStyle >
    )
}
