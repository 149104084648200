import { Box, CardActionArea, Drawer, Stack, Tooltip, Typography, useTheme } from '@material-ui/core';
// material
import { alpha, styled } from '@material-ui/core/styles';
import useFirestore from 'hooks/useFirestore';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DocIllustration } from '../../assets';
import { MHidden } from '../../components/@material-extend';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
//
import sidebarConfig from './SidebarConfig';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 82;
const APPBAR_DESKTOP = 64;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const { userSettings, workspaceSettings } = useFirestore();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        mt: 2,
        height: 1, //`calc('100%' - ${APPBAR_DESKTOP}px)`,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      {/*<Stack
        spacing={1}
        sx={{
          px: 2,
          pt: 1,
          pb: 1,
          alignItems: 'center'
        }}
      > 
        <Stack direction='row-reverse' width={1} alignItems='normal'>
          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
            {isCollapse &&
              <Box sx={{ width: 1, height: 34, pb: 1, pt: 1, px: 2 }} />
            }
          </MHidden>
        </Stack>
       <Box
          component={RouterLink}
          to="/"
          sx={{ display: 'inline-flex', width: 0.6 }}
          alignItems="center"
          justifyItems="center"
        >
          <Logo variant={isCollapse ? 'icon' : 'logo'} />
        </Box>
        
      </Stack>
*/}
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <>
          <Stack
            spacing={3}
            alignItems="center"
            sx={{ px: 5, pb: 5, mt: 2, width: 1, textAlign: 'center' }}
          >
            <DocIllustration sx={{ width: 1 }} />
            {/* 
          <div>
            <Typography gutterBottom variant="subtitle1">
              Hi, {user?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Need help?
              <br /> Please check our docs
            </Typography>
          </div>
          <Button href={PATH_DOCS} target="_blank" variant="contained">
            Documentation
          </Button>
           */}
          </Stack>
          <Box sx={{ p: 2, backgroundColor: theme.palette.grey[50] }} width={1} >
            <Typography width={1} textAlign='center' variant="subtitle2" sx={{ color: 'text.primary' }}>
              {workspaceSettings.Name}
            </Typography>
            <Typography width={1} textAlign='center' variant="body2" sx={{ color: 'text.secondary' }}>
              {userSettings.WorkspaceId}
            </Typography>
          </Box>
        </>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              mt: `${APPBAR_DESKTOP}px`,
              width: DRAWER_WIDTH
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              mt: `${APPBAR_DESKTOP}px`,
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
