import { Button, IconButton, Theme, useTheme } from "@material-ui/core";
import { UploadFilePopup } from "./UploadFilePopup";
import { useState } from "react";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { SxProps } from "@material-ui/system";
import { ButtonColors, ButtonSubColors, CircleIconButton } from "../CicrleIconButton";

export type UploadButtonVariant = 'button' | 'icon' | 'iconFilled';

export interface UploadFileButtonProps {
    variant?: UploadButtonVariant;
    color: ButtonColors;
    sx?: SxProps<Theme>;
    subColor?: ButtonSubColors;
}

export function UploadFileButton({ variant = 'icon', color, subColor = 'main', sx = null }: UploadFileButtonProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onClick = () => setIsOpen(true);

    const theme = useTheme();
    const actualColor = theme.palette[color][subColor];

    return (
        <>
            <UploadFilePopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
            {variant === 'icon' &&
                <IconButton
                    size='large'
                    onClick={onClick}
                    sx={{ ...sx, color: actualColor }}
                >
                    <CloudUploadIcon />
                </IconButton>
            }
            {variant === 'iconFilled' &&
                <CircleIconButton color={color} onClick={onClick} colorVariant={subColor} icon={<CloudUploadIcon />} />
            }
            {variant === 'button' &&
                <Button
                    onClick={onClick}
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                    sx={{ ...sx, backgroundColor: actualColor }}
                >
                    Upload File
                </Button>
            }
        </>
    );
}