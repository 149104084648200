import { GoogleAuthWidget } from "./GoogleOAuthForm"
import { PipebaseHiddenField } from "./PipebaseFormFieldsEx"
import { PipebaseOneOfField } from "./PipebaseOneOfField"
import { RelogsFormStringField, RelogsFormNumberField, RelogsFormBooleanField, RateWidget, PipebaseSchemaField } from "./RelogsFormFields"

export const RelogsFiedsRegistry = {
    StringField: RelogsFormStringField,
    NumberField: RelogsFormNumberField,
    BooleanField: RelogsFormBooleanField,
    PipebaseOneOfField: PipebaseOneOfField,
    PipebaseSchemaField: PipebaseSchemaField,
    OneOfField: PipebaseOneOfField,
    PipebaseHiddenField: PipebaseHiddenField
}

export const RelogsWidgetsRegistry = {
    RateWidget: RateWidget,
    GoogleAuthWidget: GoogleAuthWidget
}