import { TextField } from "@material-ui/core";
import { DatePicker, DateTimePicker } from "@material-ui/lab";
import moment, { unitOfTime } from "moment";
import { Moment } from "moment";
import { RelogsDateFormat, RelogsDateTimeExtnededFormat } from "../helper_functions";
import { ResolveFormValue } from "./PipebaseFormShared";
import { ErrorSchema, FieldProps } from "@rjsf/core";
import { useEffect, useState } from "react";
import { isString } from "lodash";

export function PipebaseHiddenField(fieldProps: FieldProps) {
    const value = ResolveFormValue(fieldProps);
    const [isValueChanged, setValueChanged] = useState<boolean>(false);

    useEffect(() => {
        if (value !== null && value !== undefined && !isValueChanged) {
            return;
        }

        fieldProps.onChange(value);
        setValueChanged(true);
    }, [fieldProps]);

    return (<></>);
}

function resolveDateTimeValue(fieldProps: FieldProps): Moment {
    const value = ResolveFormValue(fieldProps);
    if (!!value) {
        return value;
    }

    const uiOptions = fieldProps?.uiSchema['ui:options'];
    if (!uiOptions) {
        return null;
    }

    const defaultValue = uiOptions['date-time-defaultValue'] as string;
    if (defaultValue && isString(defaultValue)) {
        return moment(defaultValue);
    }

    const offset = uiOptions['date-time-offset'] as string;
    if (!offset || !isString(offset)) {
        return null;
    }
    const offsetUnit = offset.charAt(offset.length - 1) as unitOfTime.DurationConstructor;
    const offsetSize = +offset.substring(0, offset.length - 1);
    return moment().add(offsetSize, offsetUnit);
}

function handleDateValue(value: any, format: string, onChange: (val: any, e?: ErrorSchema) => void) {
    let date: Moment = value as Moment;
    if (!value?.format && !!value?.toDateString) {
        date = moment(value.toString());
    }

    let formValue: string = null;
    if (moment.isMoment(date)) {
        formValue = moment.utc(date).format(format);
        onChange(formValue);
        return date;
    }
}

interface PipebaseFormDateTimeBaseFieldProps {
    fieldProps: FieldProps;
    format: string;
    createComponent: (value: Moment, label: string, onChange: (v: any) => void, renderInput: (params: any) => JSX.Element) => JSX.Element;
}

export function PipebaseFormDateTimeBaseField({ fieldProps, format, createComponent }: PipebaseFormDateTimeBaseFieldProps) {
    const { schema, name, onChange, formData } = fieldProps;
    const [dateTime, setDateTime] = useState<Moment>(moment());

    const onValueChanged = (newValue: any) => {
        const dt = handleDateValue(newValue, format, onChange);
        if (!dt.isSame(dateTime)) {
            setDateTime(dt);
        }
    }

    const renderInput = (params: any) => {
        return <TextField
            {...params}
            size="small"
            style={{ width: '100%' }}
            required={fieldProps.required}
            error={!!fieldProps.rawErrors}
        />;
    };

    useEffect(() => {
        if (!!dateTime && !!formData) {
            return;
        }

        setTimeout(() => {
            const value = resolveDateTimeValue(fieldProps);
            const dateValue = !!value ? moment(value) : moment();
            onValueChanged(dateValue);
        }, 150);
    }, [fieldProps]);

    return createComponent(dateTime, schema?.title ?? name, onValueChanged, renderInput);
}

export function PipebaseFormDateStringField(fieldProps: FieldProps) {
    const createComponent = (value: Moment, label: string, onChange: (v: any) => void, renderInput: (params: any) => JSX.Element) => {
        return (
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                renderInput={renderInput}
            />
        );
    };

    return (<PipebaseFormDateTimeBaseField fieldProps={fieldProps} format={RelogsDateFormat} createComponent={createComponent} />);
}

export function PipebaseFormDateTimeStringField(fieldProps: FieldProps) {
    const createComponent = (value: Moment, label: string, onChange: (v: any) => void, renderInput: (params: any) => JSX.Element) => {
        return (
            <DateTimePicker
                ampm={false}
                label={label}
                value={value}
                onChange={onChange}
                renderInput={renderInput}
            />
        );
    };

    return (<PipebaseFormDateTimeBaseField fieldProps={fieldProps} format={RelogsDateTimeExtnededFormat} createComponent={createComponent} />);
}

export const PipebaseFieldFormatMap: { [format: string]: (fieldProps: FieldProps) => JSX.Element } = {
    'date': PipebaseFormDateStringField,
    'date-time': PipebaseFormDateTimeStringField
};