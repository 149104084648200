import { Moment } from "moment";
import { KustoColumn, KustoDatabase, KustoDatabases } from "../../../../@types/relogsDataPlane";
import { ColumnSchema } from "./ColumnSchema";
import { ExtendedColumnType } from "./ColumnType";

export const RELOGS_DEFAULT_TABLE_FOLDER = "General";
export const RELOGS_TABLE_DELIMITER = "__";
export const PIPEBASE_TABLE_FOLDER_REGEX = /^[a-zA-Z]{1}[a-zA-Z0-9\.\-]{2,31}$/;
export const PIPEBASE_TABLE_NAME_REGEX = /^[a-zA-Z]{1}[a-zA-Z0-9\.\-]{3,63}$/;
export const PIPEBASE_TABLE_FOLDER_REGEX_ERROR_MSG = "Folder name doesn't meet requirements, Folder name should start with a letter and be between 3 to 32 letters, Possible chars: [0-9, a-z, A-Z, -, _, .]";
export const PIPEBASE_TABLE_NAME_REGEX_ERROR_MSG = "Table name doesn't meet requirements, Table name should start with a letter and be between 4 to 64 letters, Possible chars: [0-9, a-z, A-Z, -, _, .]";

export type TablePathInfo = {
    TableFullPath: string;
    TableName: string;
    Folder: string
};

export type TableSchema = {
    Columns: ColumnSchema[];
    Name: string;
    Database: string;
    Statistics?: TableStatistics;
    Details?: TableDetails;
    DisplayName: string;
};

export type TableStatistics = {
    Size?: number;
    Rows?: number;
    Retention?: Moment;
    MinIngetsion?: Moment;
    MaxIngetsion?: Moment;
    CreationTime?: Moment;
}

export type TableDetails = {
    sourceName?: string;
    additionalInfo?: string;
}

export function createTableSchema(name: string, database: string, columns: ColumnSchema[], statistics: TableStatistics = null, details = null, displayName: string = null): TableSchema {
    return {
        Name: name,
        Database: database,
        Columns: columns,
        Statistics: statistics,
        Details: details,
        DisplayName: displayName ?? createTableDisplayName(name)
    } as TableSchema;
}

export function getTablePathInfo(table: TableSchema): TablePathInfo {
    return getTableNameAndFolder(table.Name);
}

export function getTableNameAndFolder(tableOriginName: string): TablePathInfo {
    if (!tableOriginName.includes(RELOGS_TABLE_DELIMITER)) {
        return {
            TableFullPath: tableOriginName,
            TableName: tableOriginName,
            Folder: RELOGS_DEFAULT_TABLE_FOLDER
        }
    }

    const folderLocation = tableOriginName.indexOf(RELOGS_TABLE_DELIMITER);
    const folder = tableOriginName.substring(0, folderLocation);
    const table = tableOriginName.substring(folderLocation + RELOGS_TABLE_DELIMITER.length);
    return {
        TableFullPath: tableOriginName,
        TableName: table,
        Folder: folder
    };
}

export function getTablesFromDatabase(db: KustoDatabase): TableSchema[] {
    let result: TableSchema[] = [];
    if (!db) {
        return result;
    }

    for (let tableName in db.Tables) {
        const cols = db.Tables[tableName].OrderedColumns.map(createColumnSchema);
        let details: TableDetails = null;
        if (!!db.Tables[tableName].DocString) {
            details = JSON.parse(db.Tables[tableName].DocString) as TableDetails;
        }

        result.push(createTableSchema(tableName, db.Name, cols, null, details))
    }

    return result;
}

function createColumnSchema(kuCol: KustoColumn, index: number): ColumnSchema {
    return {
        Name: kuCol.Name,
        Type: ExtendedColumnType.fromKqlType(kuCol.CslType)
    };
}

export function getTablesFromSchema(schema: KustoDatabases): TableSchema[] {
    let result: TableSchema[] = [];
    if (!schema) {
        return result;
    }

    for (let databaseName in schema.Databases) {
        let database = schema.Databases[databaseName];
        result = result.concat(getTablesFromDatabase(database));
    }

    return result;
}

export function createTableDisplayName(tableName: string): string {
    const tablePath = getTableNameAndFolder(tableName);
    return `${tablePath.Folder}/${tablePath.TableName}`;
}

export function sortTablesBySizeAndName(t1: TableSchema, t2: TableSchema) {
    const t1Size = t1?.Statistics?.Size ?? 0;
    const t2Size = t2?.Statistics?.Size ?? 0;
    if (t1Size > t2Size) return -1;
    if (t1Size < t2Size) return 1;
    if (t1.Name > t2.Name) return -1;
    if (t1.Name < t2.Name) return 1;

    return 0;
}