import firebase from 'firebase/app';
import { UserSettings } from "../../../../@types/firestore";
import { Moment } from 'moment';

export type PipebaseEnvironment = 'Dev' | 'Test' | 'Prod';

export type ControlPlaneOperationStatus =
    'Completed' |
    'Failed' |
    'PartiallyCompleted' |
    'InProgress' |
    'Canceled' |
    'Deleted';

export type ControlPlaneAsyncOperation = {
    workspaceId: string;
    operationId: string;
    operationName: string;
    status: ControlPlaneOperationStatus;
    duration: string;
    createdAt: string;
    endedAt: string;
    lastUpdated: string;
    response: any;
}

export type WorkspaceMembershipRole = 'Owner' | 'Contributor' | 'Reader' | 'Writer' | 'ReaderWriter'

type FirestoreTimestamp = firebase.firestore.Timestamp;
export type WorkspaceMembership = {
    Id: string;
    WorkspaceId: string;
    Role: WorkspaceMembershipRole;
    Labels: string[];
    CreatedAt: FirestoreTimestamp;
    UpdatedAt: FirestoreTimestamp;
}

export type UserMembershipInfo = {
    user: UserSettings;
    memberships: WorkspaceMembership[];
}

export type GetWorkspaceUsersResponse = {
    users: UserMembershipInfo[];
}

export type UserInviteRequest = {
    Name: string;
    Email: string;
    Role: WorkspaceMembershipRole;
}

export type PipebaseRequestError = {
    operationName: string;
    errorMessage: string;
    statusCode: number;
}

export function isPipebaseRequestError(value: any) {
    let pbError = value as PipebaseRequestError;
    return pbError.operationName !== undefined && pbError.errorMessage !== undefined && pbError.statusCode !== undefined;
}

export type IngestResult = {
    status: ControlPlaneOperationStatus;
    errorCode: number | null;
    details: string;
    ingestedTables: string[];
    ingestionSourceId?: string;
}

export type QueryInfo = {
    Text: string;
    Database: string;
    CreatedAt: Moment;
    Duration: Moment;
    State: 'Completed' | 'Failed';
};

export const QueryInfo_DEFAULT = {
    Text: null,
    Database: null,
    CreatedAt: null,
    Duration: null,
    State: null
} as QueryInfo;