import { KustoDatabases } from "../@types/relogsDataPlane";
import { ReactNode, createContext, useEffect, useState } from "react";
import useRelogsDataPlane from "hooks/useRelogsDataPlane";
import useRelogsControlPlane from "hooks/useRelogsControlPlane"
import useFirestore from "hooks/useFirestore";
import moment, { Moment } from "moment";
import { TableSchema, getTablesFromSchema } from "components/pipebase-io/common/entities/TableSchema";
import { WorkspaceSettings } from "../@types/firestore";
import { ControlPlaneAsyncOperation } from "components/pipebase-io/common/entities/Workspace";

export type PipebaseWorkspaceState = {
    WorkspaceId: string;
    WorkspaceSettings: WorkspaceSettings;
    WorkspaceTables: TableSchema[];
    AllTables: TableSchema[];
    Databases: KustoDatabases;

    deleteWorkspace: () => Promise<ControlPlaneAsyncOperation>;
    refresh: () => Promise<void>;
    getDatabases: () => Promise<KustoDatabases>;

    getAllTables: () => Promise<TableSchema[]>;
    getWorkspaceTables: () => Promise<TableSchema[]>;
    deleteTables: (tables: string[]) => Promise<void>;
}

export const PipebaseWorkspaceContext = createContext({} as PipebaseWorkspaceState);

type PipebaseWorkspaceStateProviderProps = {
    children: ReactNode;
}

const REFRESH_INTERVAL_MS = 60 * 1000;

export function PipebaseWorkspaceStateProvider({ children }: PipebaseWorkspaceStateProviderProps) {
    // Hooks
    const controlplaneClient = useRelogsControlPlane();
    const dataplaneClient = useRelogsDataPlane();
    const { workspaceSettings } = useFirestore();

    // State
    const [databases, setDatabases] = useState<KustoDatabases>(undefined);
    const [workspaceTables, setWorkspaceTables] = useState<TableSchema[]>(undefined);
    const [allTables, setAllTables] = useState<TableSchema[]>(undefined);
    const [lastSync, setLastSync] = useState<Moment>(undefined);

    const fetchAndUpdateTablesDetails = async (allTables: TableSchema[]) => {
        try {
            const kustoTables = await dataplaneClient.getTables();
            for (let kuTable of kustoTables) {
                const pipebaseTableIndex = allTables.findIndex(t => t.Database === kuTable.databaseName && t.Name === kuTable.tableName);
                if (pipebaseTableIndex >= 0) {
                    allTables[pipebaseTableIndex].Statistics = {
                        Size: kuTable.totalOriginSize,
                        Rows: kuTable.totalRowCount,
                        MinIngetsion: moment(kuTable.minExtent),
                        MaxIngetsion: moment(kuTable.maxExtent),
                        Retention: moment(kuTable.retentionPolicy.SoftDeletePeriod)
                    };
                }
            }
        }
        catch (error) {
            console.error('fetchAndUpdateTablesDetails: Failed to fetch info');
            console.log(error);
        }

        return allTables;
    }

    const fetchDatabasesData = async () => {
        try {
            const dbs = await dataplaneClient.getSchema();
            setDatabases(dbs);

            const allTables = await fetchAndUpdateTablesDetails(
                getTablesFromSchema(dbs)
            );
            setAllTables(allTables);

            const workspaceTables = allTables.filter(t => t.Database === workspaceSettings?.Id);
            setWorkspaceTables(workspaceTables);
        }
        catch (error) {
            console.error('fetchDatabasesData: Failed to fetch info');
            console.error(error);
        }
    };

    const refreshAsync = async () => {
        await fetchDatabasesData();
        setLastSync(moment());
    };

    const refreshOperation = () => {
        refreshAsync();
    };

    const isRefreshRequired = () => {
        if (!workspaceSettings?.Id) {
            return false;
        }

        if (!databases || !workspaceTables || !allTables) {
            return true;
        }

        if (!lastSync || moment().isAfter(lastSync.add(REFRESH_INTERVAL_MS, 'millisecond'))) {
            return true;
        }

        return false;
    };

    const deleteWorkspace = () => {
        return controlplaneClient.deleteWorkspace();
    }

    const getAllTables = async () => {
        if (isRefreshRequired()) {
            await refreshAsync();
        }

        return allTables;
    };

    const getWorkspaceTables = async () => {
        if (isRefreshRequired()) {
            await refreshAsync();
        }

        return workspaceTables;
    };

    const getDatabases = async () => {
        if (isRefreshRequired()) {
            await refreshAsync();
        }

        return databases;
    };

    const deleteTables = async (tables: string[]) => {
        try {
            const newAllTables = [...allTables].filter(t => !tables.includes(t.Name));
            setAllTables(newAllTables);
            const newWorkspaceTables = newAllTables.filter(t => t.Database === workspaceSettings?.Id);
            setWorkspaceTables(newWorkspaceTables);

            await controlplaneClient.tablesDelete(tables);
        } catch {
            setAllTables(allTables);
            setWorkspaceTables(workspaceTables);
        }

        refreshAsync();
    }

    useEffect(() => {
        if (!workspaceSettings || !workspaceSettings.Id) {
            console.info("Can't refresh Workspace state, waiting for workspace")
            return;
        };

        //const refershId = setInterval(refreshOperation, REFRESH_INTERVAL_MS);
        refreshOperation();
        //return () => clearInterval(refershId);
    }, [workspaceSettings]);

    return (
        <PipebaseWorkspaceContext.Provider
            value={{
                WorkspaceId: workspaceSettings?.Id,
                WorkspaceSettings: workspaceSettings,
                AllTables: allTables,
                WorkspaceTables: workspaceTables,
                Databases: databases,

                deleteWorkspace: deleteWorkspace,
                refresh: refreshAsync,

                getDatabases: getDatabases,

                getAllTables: getAllTables,
                getWorkspaceTables: getWorkspaceTables,
                deleteTables: deleteTables,
            }}
        >
            {children}
        </PipebaseWorkspaceContext.Provider>
    )
}