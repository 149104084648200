import { Autocomplete, Grid, GridProps, TextField, Typography, createFilterOptions } from "@material-ui/core";
import usePipebaseWorkspace from "hooks/usePipebaseWorkspace";
import { PIPEBASE_TABLE_FOLDER_REGEX, PIPEBASE_TABLE_FOLDER_REGEX_ERROR_MSG, PIPEBASE_TABLE_NAME_REGEX, PIPEBASE_TABLE_NAME_REGEX_ERROR_MSG, TablePathInfo, getTablePathInfo } from "../common/entities/TableSchema";
import { useEffect, useState } from "react";
import { arrayUnique, sortStringsNonOrdinal } from "../common/helper_functions";
import { usePipebaseWorkspaceRefresh } from "hooks/usePipebaseWorkspaceRefresh";

export type CreateTableForm = {
    WorkspaceId: string;
    Folder: string;
    Table: string;
};

const alignmentSx = { display: 'flex', justifyItems: 'center', alignItems: 'center' };
const folderFilter = createFilterOptions<string>();
const tableFilter = createFilterOptions<string>();


export interface PipebaseCreateOrSelectTableProps extends GridProps {
    hasError?: boolean;
    fullWidth?: boolean;
    tableValue?: string;
    folderValue?: string;
    onFolderChanged?: (newFolder: string, isNewValue: boolean) => void;
    onTableChanged?: (newTable: string, isNewValue: boolean) => void;
}

export function PipebaseCreateOrSelectTable({ folderValue = null, tableValue = null, hasError = false, fullWidth = false, onFolderChanged = null, onTableChanged = null, ...gridPros }: PipebaseCreateOrSelectTableProps) {
    const { WorkspaceId, WorkspaceTables } = usePipebaseWorkspace();
    const { lastRefresh } = usePipebaseWorkspaceRefresh();
    const [tablesInfo, setTablesInfo] = useState<TablePathInfo[]>(null);
    const [availableFolders, setAvailableFolders] = useState<string[]>(null);
    const [availableTables, setAvailableTables] = useState<string[]>(null);
    const [folderError, setFolderError] = useState<string>(null);
    const [tableError, setTableError] = useState<string>(null);
    const [selectedFolder, setSelectedFolder] = useState<string>(folderValue);
    const [selectedTable, setSelectedTable] = useState<string>(tableValue);

    useEffect(() => {
        if (!WorkspaceId || !WorkspaceTables) {
            return;
        }

        const allTablesInfo = WorkspaceTables.map(t => getTablePathInfo(t));
        setTablesInfo(allTablesInfo);
        const folders = sortStringsNonOrdinal(arrayUnique(allTablesInfo.map(t => t.Folder))) ?? [];
        setAvailableFolders(folders);
    }, [WorkspaceTables, lastRefresh]);

    useEffect(() => {
        if (!tablesInfo || !availableFolders || availableFolders.length === 0 || !selectedFolder) {
            setAvailableTables([]);
            return;
        }

        setAvailableTables(tablesInfo.filter(t => t.Folder === selectedFolder).map(t => t.TableName));
    }, [availableFolders, selectedFolder])

    const handleFolderChanged = (newValue: string) => {
        const normalizedValue = newValue?.replace('(New) ', '');
        if (!normalizedValue) {
            setSelectedFolder(normalizedValue);
            setSelectedTable(null);
            setFolderError(null);
            setTableError(null);
            return;
        }

        if (normalizedValue === selectedFolder) {
            return;
        }

        const isNewValue = availableFolders.indexOf(normalizedValue) < 0;
        if (isNewValue && !PIPEBASE_TABLE_FOLDER_REGEX.test(normalizedValue)) {
            setFolderError(PIPEBASE_TABLE_FOLDER_REGEX_ERROR_MSG);
            return;
        }

        setSelectedFolder(normalizedValue);
        setSelectedTable(null);
        setFolderError(null);
        setTableError(null);

        if (!!onFolderChanged) {
            onFolderChanged(normalizedValue, isNewValue);
        }
    }

    const handleTableChanged = (newValue: string) => {
        const normalizedValue = newValue?.replace('(New) ', '');
        if (!normalizedValue) {
            setSelectedTable(normalizedValue);
            setTableError(null);
            return;
        }

        if (normalizedValue === selectedTable) {
            return;
        }

        const isNewValue = tablesInfo.map(t => t.TableName).indexOf(normalizedValue) < 0;
        if (isNewValue && !PIPEBASE_TABLE_NAME_REGEX.test(normalizedValue)) {
            setTableError(PIPEBASE_TABLE_NAME_REGEX_ERROR_MSG);
            return;
        }

        setSelectedTable(normalizedValue);
        setTableError(null);

        if (!!onTableChanged) {
            onTableChanged(normalizedValue, isNewValue);
        }
    }

    const gridSize = fullWidth ? 10 : 5;

    return (
        <Grid container spacing={1} rowSpacing={2} sx={{ p: 2 }} {...gridPros}>
            <Grid item xs={12} md={2} sx={alignmentSx}>
                <Typography sx={alignmentSx} variant="subtitle1">Folder</Typography>
            </Grid>
            <Grid item xs={12} md={gridSize}>
                <Autocomplete
                    id='Folder'
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys

                    size="small"
                    value={selectedFolder}
                    onChange={(_, v) => handleFolderChanged(v)}
                    options={availableFolders ?? []}

                    loading={!availableFolders}
                    loadingText="Loading folders..."
                    noOptionsText="No folders found"

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name='Folder'
                            label='Folder'
                            placeholder='Enter folder name'
                            required
                            error={!!folderError || hasError}
                            helperText={folderError}
                        />
                    )}

                    filterOptions={(options, params) => {
                        const filtered = folderFilter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push(`(New) ${params.inputValue}`);
                        }

                        return filtered;
                    }}
                />
            </Grid>
            {!fullWidth && <Grid item xs={12} md={5} />}
            <Grid item xs={12} md={2} sx={alignmentSx}>
                <Typography sx={alignmentSx} variant="subtitle1">Table</Typography>
            </Grid>
            <Grid item xs={12} md={gridSize}>
                <Autocomplete
                    id='Table'
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disabled={!selectedFolder}

                    size="small"
                    value={selectedTable}
                    onChange={(_, v) => handleTableChanged(v)}
                    options={availableTables ?? []}

                    loading={!tablesInfo}
                    loadingText="Loading tables..."
                    noOptionsText="No tables found"

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name='Table'
                            label={!selectedFolder ? 'Please selected folder' : 'Table name'}
                            placeholder={!selectedFolder ? 'Please selected folder' : 'Enter table name'}
                            required
                            error={!!tableError || hasError}
                            helperText={tableError}
                        />
                    )}

                    filterOptions={(options, params) => {
                        const filtered = tableFilter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push(`(New) ${params.inputValue}`);
                        }

                        return filtered;
                    }}
                />
                {!fullWidth && <Grid item xs={12} md={5} />}
            </Grid>
        </Grid>
    )
}