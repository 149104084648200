import moment, { Moment } from "moment";

export function formatBytesSplit(bytes: number, decimals: number = 2) {
    if (!+bytes) return { size: 0, unit: 'Bytes' };

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return {
        size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        unit: sizes[i]
    };
}

export function formatBytes(bytes: number, decimals: number = 2) {
    const res = formatBytesSplit(bytes, decimals);
    return `${res.size} ${res.unit}`;
}

export const RelogsDateFormat = 'YYYY-MM-DD';
export const RelogsTimeSpanFormat = 'HH:mm:ss';
export const RelogsDateTimeFormat = `${RelogsDateFormat} ${RelogsTimeSpanFormat}`;
export const RelogsDateTimeExtnededFormat = `${RelogsDateFormat}[T]${RelogsTimeSpanFormat}[Z]`;

export function DateTimeToString(dateTime: Moment) {
    return dateTime.format(RelogsDateTimeFormat);
}

export function DateTimeFromString(dateTimeValue: string) {
    return moment(dateTimeValue);
}

export function SerializeDateTime(dateTimeValue: string) {
    const dateTime = DateTimeFromString(dateTimeValue);
    const result = DateTimeToString(dateTime);
    return result;
}

export function TimeSpanToString(timespan: Moment) {
    return timespan.format(RelogsTimeSpanFormat);
}

export function TimeSpanFromString(timespanValue: string) {
    return moment(timespanValue);
}


export function SerializeTimespan(dateTimeValue: string) {
    return TimeSpanToString(TimeSpanFromString(dateTimeValue));
}

export function arrayUnique<T>(arr: T[]): T[] {
    return [...new Set(arr)]
}

export function sortStringsNonOrdinal(options: string[]) {
    return options.sort((a, b) => {
        const normalizedA = a.toLowerCase();
        const normalizedB = b.toLowerCase();

        if (normalizedA < normalizedB) return -1;
        if (normalizedA > normalizedB) return 1;

        return 0;
    });
}