import { createContext, ReactNode } from 'react';
import { KustoDatabase, KustoDatabases, RelogsDataPlaneContextProps, Table, Table_DEFAULT } from '../@types/relogsDataPlane';
import useFirestore from 'hooks/useFirestore';
import { Client as KustoClient, KustoConnectionStringBuilder } from 'azure-kusto-data';

// import axios from '../../utils/axios';
import useRelogsControlPlane from 'hooks/useRelogsControlPlane';
import axios from 'axios';
import { IngestResult, QueryInfo, QueryInfo_DEFAULT } from 'components/pipebase-io/common/entities/Workspace';
import { KqlExecute } from 'components/pipebase-io/common/kusto/kql_helper_functions';
import { TableSchema } from 'components/pipebase-io/common/entities/TableSchema';

const RelogsDataPlaneContext = createContext({} as RelogsDataPlaneContextProps);

type RelogsDataPlaneProviderProps = {
  children: ReactNode;
};

function RelogsDataPlaneProvider({ children }: RelogsDataPlaneProviderProps) {
  const { getKustoToken } = useRelogsControlPlane();
  const clusterName = 'rcocus.centralus';// todo from settings
  const { workspaceSettings } = useFirestore();
  const workspaceId = workspaceSettings?.Id;

  const ingestUri = process.env.hasOwnProperty('REACT_APP_PIPEBASE_INGEST_URI')
    ? `${process.env.REACT_APP_PIPEBASE_INGEST_URI}`
    : `https://pipebase.io/ingest`;

  const connectionString = `https://${clusterName}.kusto.windows.net`;

  const getKustoClient = async () => {
    const kustoToken = await getKustoToken();

    console.log("Connecting to Kusto...");
    const kcsb = KustoConnectionStringBuilder.withAccessToken(connectionString, kustoToken);
    return new KustoClient(kcsb);
  }

  const _getTables = async (databaseName) => {
    const command = '.show tables details';
    const caseSensitive = false;
    const colMapping = {
      'totalOriginSize': 'TotalOriginalSize',
      'minExtent': 'MinExtentsCreationTime',
      'maxExtent': 'MaxExtentsCreationTime'
    };

    const client = await getKustoClient();

    console.log("_getTables: Fetching tables...");
    var tables = await KqlExecute<Table>(client, databaseName, command, () => Table_DEFAULT, t => t.retentionPolicy = JSON.parse(t.retentionPolicy), colMapping, caseSensitive);

    console.log("_getTables: %d tables found", tables.length);
    return tables;
  };

  const getTables = async () => {
    const workspaceTables = await _getTables(workspaceId);
    const sampleTables = await _getTables("Samples");
    return workspaceTables.concat(sampleTables);
  };

  const getSchema = async () => {
    const client = await getKustoClient();

    console.log("Fetching schema...");
    var results = await client.executeMgmt(
      workspaceId,
      `.show databases schema as json | mv-expand todynamic(DatabaseSchema).Databases | project Databases=DatabaseSchema_Databases | where Databases startswith '{"Samples"' or Databases startswith '{"${workspaceId}"'`
    );

    let workspaceSchema: KustoDatabases = { Databases: {} };
    console.log("Fetched schema results:", results);
    for (let i of results.tables[0]._rows) {
      const database = i[0] as { [name: string]: KustoDatabase };
      workspaceSchema.Databases = Object.assign({}, workspaceSchema.Databases, database)
    }

    return workspaceSchema;
  };

  const runQuery = async (query: string) => {
    const client = await getKustoClient();

    console.log("Executing query: ", query);
    var results = await client.execute(
      workspaceId,
      query
    );

    console.log("Query results:", results);

    const primaryResults = results.primaryResults[0];
    const kustoColumns = primaryResults.columns;
    const kustoRows = primaryResults._rows;

    return { kustoColumns, kustoRows };
  };

  const getIngestionKey = (tableName: string) => {
    const keys = workspaceSettings?.IngestionKeys?.filter(k => k.Tables.includes(tableName)) ?? [];
    if (!!keys && keys.length > 0) {
      return keys[0];
    }

    const masterKeys = workspaceSettings?.IngestionKeys?.filter(k => k.Tables.includes('*')) ?? [];
    return masterKeys[0];
  }

  const getQueries = async (lookBackDays: number = 14) => {
    const client = await getKustoClient();
    const query = `.show queries | where Database == '${workspaceId}'  and StartedOn > ago(${lookBackDays}d) and State in ("Completed", "Failed")`;
    var queries = await KqlExecute<QueryInfo>(client, workspaceId, query, () => QueryInfo_DEFAULT, t => { });


    return queries;
  }

  const directIngest = async (folderName: string, tableName: string, content: string | ArrayBuffer, contentType: string, expand: boolean, headersIncluded: boolean) => {
    if (!workspaceSettings || !workspaceSettings.IngestionKeys || workspaceSettings.IngestionKeys.length == 0) {
      return {
        status: 'Failed',
        errorCode: 1,
        details: 'Workspace is not initialized',
        ingestedTables: []
      } as IngestResult;
    }

    const ingestionKey = getIngestionKey(tableName);
    if (!ingestionKey || !ingestionKey.Key) {
      return {
        status: 'Failed',
        errorCode: 1,
        details: 'Ingestion key not found',
        ingestedTables: []
      } as IngestResult;
    }

    try {
      const response = await axios.request({
        method: 'POST',
        baseURL: ingestUri,
        url: `v1/${workspaceId}/${folderName}/${tableName}`,
        data: content,
        headers: {
          'x-api-key': ingestionKey.Key,
          'x-pb-format': contentType,
          'Access-Control-Allow-Origin': true,
          "Content-Type": contentType
        },
        params: {
          'ingestionType': 'Direct',
          'expand': expand,
          'includeHeaders': headersIncluded
        }
      });

      return response.data as IngestResult;
    }
    catch (error) {
      const result = error?.response?.data as IngestResult;
      if (!!result) {
        return result;
      }

      throw error;
    }
  }


  return (
    <RelogsDataPlaneContext.Provider
      value={{
        getTables,
        getSchema,
        runQuery,
        directIngest,
        getQueries,
        KustoConnectionString: connectionString,
        KustoDatabaseName: workspaceId
      }}
    >
      {children}
    </RelogsDataPlaneContext.Provider>
  );
}

export { RelogsDataPlaneProvider, RelogsDataPlaneContext };
