import { Grid, Paper, Tab, Tabs, useTheme } from "@material-ui/core";
import { FieldProps, UiSchema } from "@rjsf/core";
import { useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import ObjectField from "@rjsf/core/lib/components/fields/ObjectField";
import { GetWidget } from "./PipebaseFormShared";

function GetOneOfOptionLabel(uiSchema: UiSchema, option: any): string {
    if (!!option?.title) {
        return option.title;
    }

    return GetOneOfOptionValue(uiSchema, option);
}

function GetOneOfOptionValue(uiSchema: UiSchema, option: any): string {
    const uiOptions = uiSchema['ui:options'];
    if (!uiOptions) {
        return null;
    }

    const optionSelector = uiOptions['oneOfUiSchemaSelectorProperty'] as string;
    const optionWidgetValue = !!optionSelector && !!option['properties'] ? option['properties'][optionSelector].const : null;

    return optionWidgetValue;
}

function GetOneOfOptionUiSchema(uiSchema: UiSchema, option: any) {
    const uiOptions = uiSchema['ui:options'];
    if (!uiOptions) {
        return { Widget: null, UiSchema: null };
    }

    const optionSelectorValue = GetOneOfOptionValue(uiSchema, option);
    const optionsWidgets = uiOptions['oneOfUiSchemas'] as { [propertyVal: string]: UiSchema };
    if (!optionsWidgets || !optionSelectorValue) {
        return { Widget: null, UiSchema: null };
    }

    return optionsWidgets[optionSelectorValue] as UiSchema;
}

function PipebaseOneOfFieldOption(fieldProps: FieldProps<any>, option: any) {
    const { uiSchema } = fieldProps;
    const optionUiSchema = GetOneOfOptionUiSchema(uiSchema, option) ?? uiSchema;
    const optionFieldProps = {
        ...fieldProps,
        uiSchema: optionUiSchema ?? fieldProps.uiSchema
    };
    const Widget = GetWidget(optionFieldProps, optionUiSchema);

    if (!!Widget) {
        return Widget;
    }

    const newFieldProps: FieldProps<any> = {
        ...fieldProps,
        schema: option,
        uiSchema: optionUiSchema
    }

    return (<ObjectField {...newFieldProps} />);
}

export function PipebaseOneOfField(fieldProps: FieldProps<any>) {
    const { schema } = fieldProps;
    const [currentTab, setCurrentTab] = useState<number>(0);
    const theme = useTheme();
    if (!schema.oneOf || schema.oneOf.length === 0) {
        return (<></>);
    }

    if (schema.oneOf.length === 1) {
        return (
            <Paper
                elevation={3}
                sx={{ mx: 2, mt: 2, p: 1, backgroundColor: theme.palette.grey[100] }}
            >
                {PipebaseOneOfFieldOption(fieldProps, schema.oneOf[0])}
            </Paper>
        );
    }

    return (
        <TabContext value={currentTab.toString()} >
            <Grid container spacing={1} >
                <Grid item xs={12} md={11} >
                    <Tabs
                        value={currentTab.toString()}
                        onChange={(_, v) => setCurrentTab(v)}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {
                            schema.oneOf.map((opt, i) => {
                                return (<Tab key={`pb-oneof-tab-${i}`} label={GetOneOfOptionLabel(fieldProps.uiSchema, opt)} value={i.toString()} />)
                            })
                        }
                    </Tabs>
                </Grid>
            </Grid>
            <Paper
                elevation={3}
                sx={{ mx: 2, mt: 2, p: 1, backgroundColor: theme.palette.grey[100] }}
            >
                {
                    schema.oneOf.map((opt, i) => {
                        return (
                            <TabPanel key={`pb-oneof-tabpanel-${i}`} value={i.toString()}>
                                {PipebaseOneOfFieldOption(fieldProps, opt)}
                            </TabPanel>
                        )
                    })
                }
            </Paper>
        </TabContext>
    );
}