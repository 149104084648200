// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
const ROOT_POLICIES = '/policies';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, 'home'),
    users: path(ROOTS_DASHBOARD, 'data-explorer'),
    connections: path(ROOTS_DASHBOARD, 'connections'),
    newConnection: path(ROOTS_DASHBOARD, 'connections/create'),
    ingestion: path(ROOTS_DASHBOARD, 'ingestion'),
    queries: path(ROOTS_DASHBOARD, 'queries'),
    tables: path(ROOTS_DASHBOARD, 'tables'),
    settings: path(ROOTS_DASHBOARD, 'settings'),
    assistant: path(ROOTS_DASHBOARD, 'assistant'),
  }
};

export const PATH_POLCIES = {
  root: ROOT_POLICIES,
  general: {
    privacy: path(ROOT_POLICIES, 'privacy'),
    termOfUse: path(ROOT_POLICIES, 'terms-and-conditions')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
