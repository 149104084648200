import { KustoResultColumn } from 'azure-kusto-data/source/models';
import { IngestResult, QueryInfo } from 'components/pipebase-io/common/entities/Workspace';
import { Moment } from 'moment';

export type kustoColumnsAndRows = {
  kustoColumns: KustoResultColumn[],
  kustoRows: any[]
}

export type KustoColumn = {
  Name: string;
  Type: string;
  CslType: string;
};

export type KustoTable = {
  Name: string;
  Folder: string;
  DocString: string;
  OrderedColumns: KustoColumn[];
};

export type KustoDatabase = {
  Name: string;
  Tables: { [name: string]: KustoTable }
};

export type KustoDatabases = {
  Databases: { [name: string]: KustoDatabase }
};

export const DefaultKustoDatabases: KustoDatabases = { Databases: {} };

export type Table = {
  databaseName: string;
  tableName: string;
  tableSegments?: string[];
  totalOriginSize?: number;
  totalRowCount?: number;
  retentionPolicy?: any;
  minExtent?: Moment;
  maxExtent?: Moment;
  docString?: string;
};

export const Table_DEFAULT = {
  databaseName: null,
  tableName: null,
  retentionPolicy: null,
  totalRowCount: null,
  totalOriginSize: null,
  maxExtent: null,
  minExtent: null,
} as Table;

export type RelogsDataPlaneContextProps = {
  runQuery: (query: string) => Promise<kustoColumnsAndRows> | { error: any; };
  getTables: () => Promise<Table[]>;
  getSchema: () => Promise<KustoDatabases>;
  directIngest: (folderName: string, tableName: string, content: string | ArrayBuffer, contentType: string, expand: boolean, headersIncluded: boolean) => Promise<IngestResult>;
  getQueries: (lookBackDays?: number) => Promise<QueryInfo[]>;
  KustoConnectionString: string;
  KustoDatabaseName: string;
};