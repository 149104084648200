// routes
import ModalProvider from 'mui-modal-provider';
import { FirestoreProvider } from 'contexts/FirestoreContext';
import { RelogsControlPlaneProvider } from 'contexts/RelogsControlPlaneContext';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { RelogsDataPlaneProvider } from 'contexts/RelogsDataPlaneContext';
import { PipebaseWorkspaceStateProvider } from 'contexts/PipebaseWorkspaceContext';
import { PipebaseDataConnectionProvider } from 'contexts/PipebaseDataConnectionContext';
import InspectletRecording from 'components/pipebase-io/analytics/Inspectlet';
import { GoogleOAuthProvider } from '@react-oauth/google';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ModalProvider>
                <FirestoreProvider>
                  <RelogsControlPlaneProvider>
                    <RelogsDataPlaneProvider>
                      <PipebaseWorkspaceStateProvider>
                        <PipebaseDataConnectionProvider>
                          <GoogleOAuthProvider clientId="632212375435-basejr4n25fkg33chq3h8ejg65ahkdjr.apps.googleusercontent.com">
                            {/* <Settings /> */}
                            <ScrollToTop />
                            <GoogleAnalytics />
                            <InspectletRecording />
                            {isInitialized ? <Router /> : <LoadingScreen />}
                          </GoogleOAuthProvider>
                        </PipebaseDataConnectionProvider>
                      </PipebaseWorkspaceStateProvider>
                    </RelogsDataPlaneProvider>
                  </RelogsControlPlaneProvider>
                </FirestoreProvider>
              </ModalProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
