import { Autocomplete, AutocompleteRenderGroupParams, Checkbox, darken, lighten, styled, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.98)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

export interface RelogsAutocompleteOption {
    getLabel: () => string;
    getGroup: () => string;
    getDisplayView: (props: React.HTMLAttributes<HTMLLIElement>, selected: boolean) => JSX.Element;
    getId: () => string;
}

export function sortRelogsAuthComplteOptionsImpl(aGroup: string, aLabel: string, bGroup: string, bLabel: string) {
    if (aGroup < bGroup) return -1;
    if (aGroup > bGroup) return 1;
    if (aLabel < bLabel) return -1;
    if (aLabel > bLabel) return 1;

    return 0;
}

export function sortRelogsAutoCompleteOptions<T extends RelogsAutocompleteOption>(options: T[]) {
    return options.sort((a, b) => {
        const aGroupUpper = a.getGroup().toUpperCase();
        const bGroupUpper = b.getGroup().toUpperCase();
        if (aGroupUpper === bGroupUpper) {
            return sortRelogsAuthComplteOptionsImpl(a.getGroup(), a.getLabel(), b.getGroup(), b.getLabel());
        }

        return sortRelogsAuthComplteOptionsImpl(aGroupUpper, a.getLabel().toUpperCase(), bGroupUpper, b.getLabel().toUpperCase());
    });
}

export interface RelogsAutocompleteProps<T extends RelogsAutocompleteOption> {
    options: T[];
    label: string;
    placeholder: string;
    noOptionsText?: string;
    loading?: boolean;
    loadingText?: string;
    id?: string;
    multiple?: boolean;
    required?: boolean;
    disableCloseOnSelect?: boolean;
    disableClearable?: boolean;
    defaultValue?: T | T[];
    enableSearch?: boolean;
    disabled?: boolean;
    openOnFocus?: boolean;
    sortOptions?: boolean;
    onChange?: (value: T | T[]) => void;
    value?: T | T[];
}

export function RelogsAutocomplete<T extends RelogsAutocompleteOption>(
    {
        options,
        label,
        placeholder,
        noOptionsText = '',
        loading = false,
        loadingText = '',
        id = undefined,
        disableCloseOnSelect = false,
        required = false,
        multiple = false,
        enableSearch = true,
        disabled = false,
        disableClearable = false,
        openOnFocus = true,
        sortOptions = false,
        onChange = null,
        defaultValue = undefined,
        value = undefined
    }: RelogsAutocompleteProps<T>) {
    const newCheckBoxView = (selected: boolean) => {
        if (multiple) {
            return (
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
            );
        }

        return (<></>);
    };

    return (
        <Autocomplete
            id={id}
            size="small"
            style={{ display: 'flex' }}

            // Autocomplete behavior
            multiple={multiple}
            disableCloseOnSelect={disableCloseOnSelect}
            openOnFocus={openOnFocus}
            noOptionsText={noOptionsText}
            loading={loading}
            loadingText={loadingText}
            autoComplete={enableSearch}
            disabled={disabled}
            disableClearable={disableClearable}


            // Values
            value={multiple ? value as T[] : value as T}
            defaultValue={multiple ? defaultValue as T[] : defaultValue as T}
            options={sortOptions ? sortRelogsAutoCompleteOptions([...options]) : options}
            onChange={(e, v, r) => handleOnChange(v as (string | T | (string | T)[]), onChange)}

            getOptionLabel={(v) => (v as T)?.getLabel()}
            groupBy={v => (v as T)?.getGroup()}
            isOptionEqualToValue={(option, value) => (option as T)?.getId()?.toLowerCase() === (value as T).getId()?.toLowerCase()}

            // Rendering
            renderGroup={RelogsRenderGroupBy}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {newCheckBoxView(selected)}
                    {(option as T)?.getDisplayView(props, selected)}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} required={required} />
            )}
        />
    );
}

export function RelogsRenderGroupBy(params: AutocompleteRenderGroupParams) {
    return (
        <li>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
        </li>
    );
}

function handleOnChange<T extends RelogsAutocompleteOption>(values: string | T | (string | T)[], onChange?: (value: T | T[]) => void) {
    if (!onChange) {
        return;
    }

    let v: T | T[] = null;
    if (Array.isArray(values)) {
        v = values?.map(x => x as T);
    } else {
        v = values as T;
    }

    onChange(v);
}