import { DataConnection, DataConnectionConfig, DataConnectionSyncConfig } from "components/pipebase-io/common/entities/DataConnection";
import { ReactNode, createContext, useEffect, useState } from "react";
import useRelogsControlPlane from "hooks/useRelogsControlPlane"
import useFirestore from "hooks/useFirestore";
import moment, { Moment } from "moment";
import { ControlPlaneAsyncOperation } from "components/pipebase-io/common/entities/Workspace";

export type PipebaseDataConnectionState = {
    WorkspaceId: string;
    DataConnections: DataConnection[];
    dataConnectionGetConfig: (sourceDefinitionId: string) => Promise<any>;
    dataConnectionGetSchema: (sourceDefinitionId: string, connectionConfig: DataConnectionConfig) => Promise<any>;
    dataConnectionCreate: (name: string, sourceDefinitionId: string, connectionConfig: any, syncConfig: DataConnectionSyncConfig, enabledStreams: string[]) => Promise<ControlPlaneAsyncOperation>;
    dataConnectionList: () => Promise<any>;
    dataConnectionDelete: (connectionId: string) => Promise<ControlPlaneAsyncOperation>;
    dataConnectionSetState: (connectionId: string, enabled: boolean) => Promise<any>;
    dataConnectionTriggerSync: (connectionId: string) => Promise<any>;
    refresh: () => Promise<void>;
}

export const PipebaseDataConnectionContext = createContext({} as PipebaseDataConnectionState);

type PipebaseDataConnectionProviderProps = {
    children: ReactNode;
}

const REFRESH_INTERVAL_MS = 5 * 60 * 1000;

export function PipebaseDataConnectionProvider({ children }: PipebaseDataConnectionProviderProps) {
    // Hooks
    const controlPlaneClient = useRelogsControlPlane();
    const { workspaceSettings } = useFirestore();

    // State
    const [dataConnections, setDataConnections] = useState<DataConnection[]>(undefined);
    const [lastSync, setLastSync] = useState<Moment>();

    const fetchDataConnectionsData = async () => {
        try {
            const dc = await controlPlaneClient.dataConnectionList();
            setDataConnections(dc);
        }
        catch (error) {
            console.error('fetchDataConnectionsData: Failed to fetch info');
            console.error(error);
        }
    }

    const refreshAsync = async () => {
        await fetchDataConnectionsData();
        setLastSync(moment());
    };

    const isRefreshRequired = () => {
        if (!workspaceSettings?.Id) {
            return false;
        }

        if (!dataConnections || !lastSync) {
            return true;
        }

        if (dataConnections.length == 0 && moment.duration(moment().diff(lastSync)).milliseconds() > REFRESH_INTERVAL_MS) {
            return true;
        }

        return false;
    };

    const dataConnectionGetConfig = async (sourceDefinitionId: string) => {
        return await controlPlaneClient.dataConnectionGetConfig(sourceDefinitionId);
    }

    const dataConnectionGetSchema = async (sourceDefinitionId: string, connectionConfig: DataConnectionConfig) => {
        return await controlPlaneClient.dataConnectionGetSchema(sourceDefinitionId, connectionConfig);
    };

    const dataConnectionCreate = async (name: string, sourceDefinitionId: string, connectionConfig: any, syncConfig: DataConnectionSyncConfig, enabledStreams: string[]) => {
        const dc = await controlPlaneClient.dataConnectionCreate(name, sourceDefinitionId, connectionConfig, syncConfig, enabledStreams);
        await refreshAsync();
        return dc;
    }

    const dataConnectionList = async () => {
        if (isRefreshRequired()) {
            await refreshAsync();
        }

        return dataConnections;
    }

    const dataConnectionSetState = async (connectionId: string, enabled: boolean) => {
        const result = await controlPlaneClient.dataConnectionSetState(connectionId, enabled);
        const dcIdx = dataConnections.findIndex(t => t.Id == connectionId);
        if (dcIdx >= 0) {
            dataConnections[dcIdx].Enabled = enabled;
        }

        return result;
    }

    const dataConnectionTriggerSync = async (connectionId: string) => {
        controlPlaneClient.dataConnectionTriggerSync(connectionId);
        const dcIdx = dataConnections.findIndex(t => t.Id == connectionId);
        var newDc = dataConnections;
        if (dcIdx >= 0) {
            newDc = [...dataConnections];
            newDc[dcIdx].LastSyncStatus = 'pending';
        }

        setDataConnections(newDc);
        setTimeout(() => {
            refreshAsync();
        }, 15 * 1000);

        return {};
    }

    const dataConnectionDelete = async (connectionId: string) => {
        const result = await controlPlaneClient.dataConnectionDelete(connectionId);
        await refreshAsync();

        return result;
    }

    useEffect(() => {
        if (!workspaceSettings || !workspaceSettings.Id) {
            console.info("Can't refresh DataConnections, waiting for workspace")
            return;
        };

        //const refershId = setInterval(() => refreshAsync(), REFRESH_INTERVAL_MS);
        refreshAsync();
        //return () => clearInterval(refershId);
    }, [workspaceSettings]);

    return (
        <PipebaseDataConnectionContext.Provider
            value={{
                WorkspaceId: workspaceSettings?.Id,
                DataConnections: dataConnections,
                dataConnectionGetConfig: dataConnectionGetConfig,
                dataConnectionGetSchema: dataConnectionGetSchema,
                dataConnectionCreate: dataConnectionCreate,
                dataConnectionList: dataConnectionList,
                dataConnectionDelete: dataConnectionDelete,
                dataConnectionSetState: dataConnectionSetState,
                dataConnectionTriggerSync: dataConnectionTriggerSync,
                refresh: refreshAsync
            }}
        >
            {children}
        </PipebaseDataConnectionContext.Provider>
    )
}