import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import NewCustomerGuard from 'guards/NewCustomerGuard';
import WorkspaceSettingsLoadedGuard from 'guards/WorkspaceSettingsLoadedGuard';
import UserSettingsLoadedGuard from 'guards/UserSettingsLoadedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Policies Routes
    {
      path: 'policies',
      element: (
        <DashboardLayout />
      ),
      children: [
        { path: 'privacy', element: <PrivacyPolicyPage /> },
        { path: 'terms-and-conditions', element: <TermAndConditionsPage /> }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <UserSettingsLoadedGuard>
            <NewCustomerGuard>
              <WorkspaceSettingsLoadedGuard>
                <DashboardLayout />
              </WorkspaceSettingsLoadedGuard>
            </NewCustomerGuard>
          </UserSettingsLoadedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <MainPage /> },
        { path: 'ingestion', element: <IngestionPage /> },
        { path: 'connections', element: <DataConnectionsPage /> },
        { path: 'connections/create', element: <NewDataConnectionPage /> },
        { path: 'data-explorer', element: <DataExplorerPage /> },
        { path: 'tables', element: <TablesPage /> },
        { path: 'queries', element: <InDevelopmentPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'assistant', element: <InDevelopmentPage /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const DataExplorerPage = Loadable(lazy(() => import('../pages/dashboard/DataExplorerPage')));
const IngestionPage = Loadable(lazy(() => import('../pages/dashboard/IngestionPage')));
const DataConnectionsPage = Loadable(lazy(() => import('../pages/dashboard/DataConnectionsPage')));
const NewDataConnectionPage = Loadable(lazy(() => import('../pages/dashboard/NewDataConnectionPage')));
const TablesPage = Loadable(lazy(() => import('../pages/dashboard/TablesPage')));
const MainPage = Loadable(lazy(() => import('../pages/dashboard/MainPage')));
const SettingsPage = Loadable(lazy(() => import('../pages/dashboard/SettingsPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/policies/PrivacyPolicyPage')));
const TermAndConditionsPage = Loadable(lazy(() => import('../pages/policies/TermAndConditionsPage')));
const AssistantPage = Loadable(lazy(() => import('../pages/dashboard/AssistantPage')));

// Main
const InDevelopmentPage = Loadable(lazy(() => import('../pages/InDevelopmentPage')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));