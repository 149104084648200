import KustoClient from "azure-kusto-data/source/client";
import { isArray, isString } from "lodash";

export const SampleTablesPrefix = 'Samples__';

export function IsSampleTable(tableName: string): boolean {
    return tableName.startsWith(SampleTablesPrefix);
}

export function KqlTableName(tableName: string): string {
    if (IsSampleTable(tableName)) {
        return `database('Samples').table('${tableName}')`;
    }

    return tableName.includes("-") || tableName.includes(' ')
        ? (`['${tableName}']`)
        : tableName;
}

export function KqlColumnName(columnName: string): string {
    return (columnName.includes('$') && !columnName.startsWith('$')) || columnName.includes('.')
        ? `['${columnName}']` : columnName;
}

export async function KqlExecute<T>(
    client: KustoClient,
    database: string,
    query: string,
    defaultCreator: () => T,
    transform: (obj: any) => void,
    mapping: { [name: string]: string } = {},
    caseSensitive: boolean = true
): Promise<T[]> {
    console.log(`Executing operation, Workspace='${database}', Query='${query}'`);
    if (!database || !query || !isString(database) || !isString(query) || database.length === 0 || query.length === 0) {
        console.log(`Execution failed, Invalid workspace or query`);
        return [];
    }

    try {
        const cols = Object.keys(defaultCreator());
        const results = await client.execute(database, query);
        const primaryResults = !!results.primaryResults && isArray(results.primaryResults) && results.primaryResults.length > 0
            ? results.primaryResults[0]
            : results.tables[0];


        const resultMapping: { [colIndex: number]: string } = {};
        for (let colKey of cols) {
            const col = primaryResults.columns.find(c => {
                let mappedColKeyName = mapping[colKey];
                if (!mappedColKeyName) {
                    mappedColKeyName = colKey;
                }

                if (caseSensitive) {
                    return c.name === mappedColKeyName;
                }

                return c.name.toLowerCase() === mappedColKeyName.toLowerCase();
            });
            if (!!col && col.ordinal !== undefined) {
                resultMapping[col.ordinal] = colKey;
            }
        }

        return primaryResults._rows.map(r => {
            let obj = {};
            for (let colIndex of Object.keys(resultMapping)) {
                const colName = resultMapping[colIndex];
                obj[colName] = r[colIndex];
            }

            if (!!transform) {
                transform(obj);
            }

            return obj as T;
        });

    } catch (error) {
        console.log(`Execution failed, Workspace='${database}', Query='${query}'`);
    }

    return [];
}