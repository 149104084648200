import { Box, Divider, Typography } from "@material-ui/core";
import { useLocation } from "react-router";
import { FeatureInfo, getFeaturesInfoArray } from "./FeaturesInfo";
import { useEffect, useState } from "react";
import { MHidden } from "components/@material-extend";

export interface LocationViewerProps {

}

export function LocationViewer({ }: LocationViewerProps) {
    const location = useLocation();
    const [currentFeature, setCurrentFeature] = useState<FeatureInfo>({
        title: '',
        path: '/',
        icon: null,
        public: true,
    });

    useEffect(() => {
        if (!location?.pathname || location.pathname === '/' || location.pathname === '/home') {
            setCurrentFeature({
                title: 'Home',
                path: location.pathname,
                icon: null,
                public: true,
            });
            return;
        }

        const features = getFeaturesInfoArray();
        const feature = features.find(f => location.pathname.startsWith(f.path))
        if (!!feature) {
            setCurrentFeature(feature);
            return;
        }
    }, [location]);

    return (
        <Box display='flex' alignContent='center' justifyContent='center'>
            <MHidden width="mdDown">
                <Divider orientation="vertical" flexItem />
                <Typography variant="subtitle1" color="textPrimary" sx={{ ml: 1, mt: 0.5 }}>{currentFeature?.title}</Typography>
            </MHidden>
            <MHidden width="mdUp">
                <Typography variant="subtitle1" color="textPrimary">{currentFeature?.title}</Typography>
            </MHidden>
        </Box>
    )
}