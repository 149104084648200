import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { RelogsControlPlaneContextProps as RelogsControlPlaneContextProps } from '../@types/relogsControlPlane';
import useAuth from '../hooks/useAuth';
import useFirestore from 'hooks/useFirestore';

// import axios from '../../utils/axios';
import axios, { Method } from 'axios';
import { DataConnectionConfig, DataConnectionCronConfig, DataConnectionPeriodicSyncConfig, DataConnectionSyncConfig } from 'components/pipebase-io/common/entities/DataConnection';
import { ControlPlaneAsyncOperation, GetWorkspaceUsersResponse, isPipebaseRequestError, PipebaseEnvironment, PipebaseRequestError, UserInviteRequest, UserMembershipInfo } from 'components/pipebase-io/common/entities/Workspace';

const RelogsControlPlaneContext = createContext({} as RelogsControlPlaneContextProps);

type RelogsControlPlaneProviderProps = {
  children: ReactNode;
};

function convertToDataType(data: any) {
  const dictionary = data as { [key: string]: any }
  let result = {};
  for (let key in dictionary) {
    const newKey = key[0].toUpperCase() + key.substring(1);
    result[newKey] = dictionary[key];
  }

  return result as any;
}

function RelogsControlPlaneProvider({ children }: RelogsControlPlaneProviderProps) {
  const { getIdToken } = useAuth();
  const { workspaceSettings } = useFirestore();
  const [pipebaseEnvironment, setPipebaseEnvironment] = useState<PipebaseEnvironment | null>(null);
  const [kustoToken, setKustoToken] = useState<string>(null);
  const kustoTokenRef = useRef<string>(null);

  const kustoTokenRefreshRateMs = 15 * 60 * 1000;

  const workspaceName = workspaceSettings?.Id || "";

  const relogsControlPlane = process.env.hasOwnProperty('REACT_APP_PIPEBASE_CONTROLPLANE_URI')
    ? `${process.env.REACT_APP_PIPEBASE_CONTROLPLANE_URI}`
    : `https://pipebase.io/api`;

  const baseUrl = `${relogsControlPlane}/v1`;
  const workspaceUrlPart = `/workspaces/${workspaceName}`;
  const operationUrlPart = `${workspaceUrlPart}/operations`;
  const getKustoTokenUrlPart = `${workspaceUrlPart}/authenticate`;
  const dataConnectionsUrlPart = `${workspaceUrlPart}/dataconnections`
  const dataConnectionsSourceUrlPart = `${dataConnectionsUrlPart}/sourceDefinitions`

  const sendAuthenticatedRequestBase = async (method: Method, urlPart: string, body: any = {}, requiresWorkspaceSettings = true) => {
    if (requiresWorkspaceSettings && !workspaceSettings) {
      throw new Error("Missing workspace settings");
    }
    const firebaseToken = await getIdToken();

    try {
      console.debug("My firebase user token:", firebaseToken);
      const response = await axios.request({
        method: method,
        baseURL: baseUrl,
        url: urlPart,
        data: body,
        headers: {
          'Authorization': `Bearer ${firebaseToken}`,
          'Access-Control-Allow-Origin': true
        },
      });

      if (response.status > 399) {
        throw response.data as PipebaseRequestError;
      }

      return response;
    }
    catch (error) {
      if (isPipebaseRequestError(error?.response?.data)) {
        throw error?.response?.data as PipebaseRequestError;
      }

      throw error;
    }
  };

  const fetchKustoToken = async (currentToken: string, forceFetch: boolean) => {
    console.log(`fetchKustoToken: TokenInitialized=${!!currentToken}, Forced=${forceFetch}`);
    if (!forceFetch && !!currentToken) {
      return { token: currentToken, refreshed: false };
    }

    const result: any = await sendAuthenticatedRequestBase('post', getKustoTokenUrlPart);
    if (!result || result.error) {
      throw new Error(result.error);
    }

    console.log("result:", result.data);
    return { token: result.data.accessToken, refreshed: true };
  }

  const __getPipebaseEnvironment = () => {
    const env = process.env.hasOwnProperty('REACT_APP_PIPEBASE_ENVIRONMENT') ? `${process.env.REACT_APP_PIPEBASE_ENVIRONMENT}`.toLowerCase() : `prod`;
    let pbEnv: PipebaseEnvironment;
    if (env === 'dev') {
      pbEnv = 'Dev';
    }
    else if (env === 'test') {
      pbEnv = 'Test';
    }
    else {
      pbEnv = 'Prod';
    }

    setPipebaseEnvironment(pbEnv);
  }

  const getPipebaseEnvironment: any = (): PipebaseEnvironment => {
    if (pipebaseEnvironment !== null) {
      return pipebaseEnvironment;
    }

    __getPipebaseEnvironment();
  }

  const createWorkspace: any = async (workspaceName: string) => {
    return sendAuthenticatedRequestBase(
      'put',
      "/workspaces",
      {
        "displayName": workspaceName,
        "description": "My workspace", // do we need it?
      },
      false)
  };

  const deleteWorkspace: any = async () => {
    const result = await sendAuthenticatedRequestBase('delete', workspaceUrlPart);
    return result.data as ControlPlaneAsyncOperation;
  }

  const getWorkspaceOperation: any = async (operationId: string) => {
    const result: any = await sendAuthenticatedRequestBase('get', `${operationUrlPart}/${operationId}`);
    if (!result || result.error) {
      throw new Error(result.error);
    }

    return result.data as ControlPlaneAsyncOperation;
  }

  const getWorkspaceSettings: any = async () => {
    return sendAuthenticatedRequestBase(
      'get',
      workspaceUrlPart)
  };

  const getKustoToken: any = async () => {
    const tokenResult = await fetchKustoToken(kustoTokenRef.current, false);
    if (tokenResult.refreshed) {
      setKustoToken(tokenResult.token);
    }

    return tokenResult.token;
  };

  const dataConnectionGetConfig: any = async (sourceDefinitionId: string) => {
    const result: any = await sendAuthenticatedRequestBase('get', `${dataConnectionsSourceUrlPart}/${sourceDefinitionId}/config`);
    return result.data;
  }

  const dataConnectionGetSchema: any = async (sourceDefinitionId: string, connectionConfig: DataConnectionConfig) => {
    const result: any = await sendAuthenticatedRequestBase('post', `${dataConnectionsSourceUrlPart}/${sourceDefinitionId}/schema`, connectionConfig);
    return result.data;
  };

  const dataConnectionCreate: any = async (name: string, sourceDefinitionId: string, connectionConfig: any, syncConfig: DataConnectionSyncConfig, enabledStreams: string[]) => {
    let parsedSyncConfig = {
      syncType: syncConfig.syncType,
      definition: null
    };
    if (syncConfig.syncType === 'periodic') {
      let periodicConfig = syncConfig as DataConnectionPeriodicSyncConfig;
      parsedSyncConfig.definition = `${periodicConfig.interval}-${periodicConfig.unit}`;
    }
    else if (syncConfig.syncType == 'cron') {
      let cronConfig = syncConfig as DataConnectionCronConfig;
      parsedSyncConfig.definition = cronConfig.cronExpression;
    }

    const result: any = await sendAuthenticatedRequestBase('put', `${dataConnectionsUrlPart}/create`,
      {
        name: name,
        sourceDefinitionId: sourceDefinitionId,
        connectionConfig: connectionConfig,
        syncConfig: syncConfig,
        enabledStreams: enabledStreams
      });

    return result.data as ControlPlaneAsyncOperation;
  }

  const dataConnectionList: any = async () => {
    const result: any = await sendAuthenticatedRequestBase('get', `${dataConnectionsUrlPart}`);
    return result.data;
  }

  const dataConnectionSetState: any = async (connectionId: string, enabled: boolean) => {
    const result: any = await sendAuthenticatedRequestBase('post', `${dataConnectionsUrlPart}/${connectionId}/state`,
      {
        state: enabled ? "active" : "inactive"
      });

    return result.data;
  }

  const dataConnectionTriggerSync: any = async (connectionId: string) => {
    const result: any = await sendAuthenticatedRequestBase('post', `${dataConnectionsUrlPart}/${connectionId}/sync`);

    return result.data;
  }

  const dataConnectionDelete: any = async (connectionId: string) => {
    const result: any = await sendAuthenticatedRequestBase('delete', `${dataConnectionsUrlPart}/${connectionId}/delete`);

    return result.data as ControlPlaneAsyncOperation;
  }

  const tablesDelete: any = async (tablesNames: string[]) => {
    const result: any = await sendAuthenticatedRequestBase('post', `${workspaceUrlPart}/tables/drop`, {
      tables: tablesNames
    });

    return result.data.tables as string[];
  }

  const workspaceUserList: any = async () => {
    const result: any = await sendAuthenticatedRequestBase('get', `${workspaceUrlPart}/users`);
    const resp = result.data as GetWorkspaceUsersResponse;
    for (let userInfo of resp.users) {
      const user = convertToDataType(userInfo.user);
      const memberships = userInfo.memberships.map(x => convertToDataType(x));
      userInfo.user = user;
      userInfo.memberships = memberships;
    }

    return resp;
  }

  const workspaceUserInvite: any = async (request: UserInviteRequest) => {
    const result: any = await sendAuthenticatedRequestBase('put', `${workspaceUrlPart}/users/invite`, request);
    if (isPipebaseRequestError(result.data)) {
      throw result.data as PipebaseRequestError;
    }

    const user = convertToDataType(result.data.user);
    const memberships = result.data.memberships.map(convertToDataType);

    return {
      memberships: memberships,
      user: user
    } as UserMembershipInfo;
  }

  useEffect(() => {
    kustoTokenRef.current = kustoToken;
  });

  useEffect(() => {
    async function IntervalCallbackAsync(tokenRef: React.MutableRefObject<string>) {
      try {
        console.log("Refreshing kusto token...");
        const tokenResult = await fetchKustoToken(tokenRef.current, true);
        if (tokenResult.refreshed) {
          setKustoToken(tokenResult.token);
        }
      } catch (error) {
        console.error("Could not refresh Kusto token. Error:", JSON.stringify(error))
      }
    };

    const intervalId = setInterval(IntervalCallbackAsync, kustoTokenRefreshRateMs, kustoTokenRef);
    IntervalCallbackAsync(kustoTokenRef);
    return () => clearInterval(intervalId);
  }, []);


  if (pipebaseEnvironment === null) {
    __getPipebaseEnvironment();
  }

  return (
    <RelogsControlPlaneContext.Provider
      value={{
        getPipebaseEnvironment,

        createWorkspace,
        deleteWorkspace,
        getWorkspaceOperation,
        getWorkspaceSettings,
        getKustoToken,

        tablesDelete,

        workspaceUserList,
        workspaceUserInvite,

        dataConnectionGetConfig,
        dataConnectionGetSchema,
        dataConnectionCreate,
        dataConnectionList,
        dataConnectionDelete,
        dataConnectionSetState,
        dataConnectionTriggerSync
      }}
    >
      {children}
    </RelogsControlPlaneContext.Provider>
  );
}

export { RelogsControlPlaneProvider, RelogsControlPlaneContext };