import { useEffect, useState } from "react";
import usePipebaseWorkspace from "./usePipebaseWorkspace";
import { Moment } from "moment";
import moment from "moment";

const REFRESH_INTERVAL_MS = 60 * 1000;

export function usePipebaseWorkspaceRefresh() {
    const { refresh, WorkspaceSettings } = usePipebaseWorkspace();
    const [lastRefresh, setLastRefresh] = useState<Moment>(undefined);
    const refreshOperation = async () => {
        if (!WorkspaceSettings || !WorkspaceSettings.Id) {
            console.info("Can't refresh Workspace state, waiting for workspace")
            return;
        };

        await refresh();
        setLastRefresh(moment());
    }

    useEffect(() => {
        if (!WorkspaceSettings || !WorkspaceSettings.Id) {
            console.info("Can't refresh Workspace state, waiting for workspace")
            return;
        };

        const refershId = setInterval(refreshOperation, REFRESH_INTERVAL_MS);
        refreshOperation();
        return () => clearInterval(refershId);
    }, [WorkspaceSettings]);

    return { lastRefresh: lastRefresh };
}