import { createContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { FirestoreContextProps, UserSettings, WorkspaceSettings, IngestionKey } from '../@types/firestore';
import useAuth from '../hooks/useAuth';
import firebase from 'firebase/app';

const FirestoreContext = createContext({} as FirestoreContextProps);

type FirestoreProviderProps = { children: ReactNode; };

function FirestoreProvider({ children }: FirestoreProviderProps) {
  const { db, user, isAuthenticated } = useAuth();

  const [userSettings, setUserSettings] = useState<UserSettings>(undefined);
  const [workspaceSettings, setWorkspaceSettings] = useState<WorkspaceSettings>(undefined);

  //#region Effects
  useEffect(() => {
    const checkAuthStatus = async () => {
      if (isAuthenticated && user) {
        console.log("User auth changed, checking if it is a new user", user.email);

        const userResult = await db.collection('Users')
          .doc(user.email)
          .get();

        let userData: Partial<UserSettings> = {
          UpdatedAt: firebase.firestore.Timestamp.now()
        };

        if (!userResult.exists) {
          userData.CreatedAt = firebase.firestore.Timestamp.now();
          userData.Email = user.email;
        }

        await updateUserSettings(userData);
      }
    }

    checkAuthStatus();
  }, [db, user, isAuthenticated]);

  useEffect(() => {
    if (!db || !user || !isAuthenticated) {
      return;
    }

    console.debug("Fetching user info for user: ", user.email);
    const snapshotCancellation = db.collection('Users')
      .doc(user.email)
      .onSnapshot(onUserSnapshot, (error) => onSnapshotError(error, 'User', user.email));

    return snapshotCancellation;
  }, [db, user, isAuthenticated]);

  useEffect(() => {
    if (!userSettings || !userSettings.WorkspaceId) {
      return;
    }

    console.debug("Fetching workspace info for workspace: ", userSettings.WorkspaceId);
    const snapshotCancellation = db
      .collection('Workspaces')
      .doc(userSettings.WorkspaceId)
      .onSnapshot(onWorkspaceSnapshot, (error) => onSnapshotError(error, 'Workspace', userSettings.WorkspaceId));

    return snapshotCancellation;
  }, [userSettings?.WorkspaceId]);

  useEffect(() => {
    if (!userSettings || !userSettings.WorkspaceId) {
      return;
    }

    console.debug("Fetching ingestion keys for workspace: ", userSettings.WorkspaceId);
    const snapshotCancellation = db
      .collection('Workspaces')
      .doc(userSettings.WorkspaceId)
      .collection("IngestionKeys")
      .onSnapshot(onIngestionKeysSnapshot, (error) => onSnapshotError(error, 'IngestionKeys', userSettings.WorkspaceId));
    return snapshotCancellation;
  }, [userSettings]);
  //#endregion

  //#region Methods
  const onUserSnapshot = (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
    if (!snapshot.exists) {
      console.warn('Got no results for user:', user.email);
    }

    const userData = (snapshot.data() as UserSettings) ?? null;
    console.debug("Setting user info for user: ", user.email, userData);
    setUserSettings(userData);
  }

  const onWorkspaceSnapshot = (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
    if (!snapshot.exists) {
      console.warn(`Weird, got no results for workspace '${userSettings.WorkspaceId}', maybe it was deleted or still not created?`); // Todo in the future we might want to check if we have other workspaces
    }

    const workspaceData = (snapshot.data() as WorkspaceSettings) ?? null;
    console.debug("Setting workspace info for workspace: ", userSettings.WorkspaceId, workspaceData);
    setWorkspaceSettings(settings => ({ ...settings, ...workspaceData }));
  }

  const onIngestionKeysSnapshot = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
    if (snapshot.empty) {
      console.warn('Got no ingestion keys for workspace:', userSettings.WorkspaceId);
      return;
    }

    const docs = snapshot.docs.map(doc => doc.data());
    console.debug("Setting ingestion keys for workspace: ", userSettings.WorkspaceId, docs);
    setWorkspaceSettings(settings => ({ ...settings, IngestionKeys: docs as unknown as IngestionKey[] }));
  }

  const onSnapshotError = (error: firebase.firestore.FirestoreError, resourceType: string, resource: string) => {
    console.error(`Failed to get ${resourceType}: ${resource}`);
    console.error(error);
  }

  const updateUserSettings = async (data: Partial<UserSettings>) => {
    if (!data.UpdatedAt) {
      data.UpdatedAt = firebase.firestore.Timestamp.now()
    }

    data.Name = user.displayName ?? '';
    data.PhotoUrl = user.photoURL ?? '';
    data.Status = 'Active';

    console.log(`Updating user settings for ${user.email} with:`, data);
    await db.collection('Users')
      .doc(user.email)
      .set(data, { merge: true })
  }

  const batchUpdate = useCallback(async (refAndUpdates: Map<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, Object>) => {
    console.log("Running a firestore batch update...", refAndUpdates);
    let batch: firebase.firestore.WriteBatch = null;
    let index = 0;
    for (let [key, value] of refAndUpdates) {
      if (index === 0) {
        console.log("Creating a new firestore batch");
        batch = db.batch();
      }

      batch.set(key, value, { merge: true }); // Set let us create if not exists

      index += 1;
      if (index === 499) {
        console.log("Commiting batch...");
        await batch.commit();
        console.log("Firestore batch sent.");
        index = 0;
      }
    }

    if (index > 0) {
      console.log("Commiting last batch...");
      await batch.commit();
      console.log("Last firestore batch sent.");
    }
  }, [db]);

  const batchDelete = useCallback(async (refs: Array<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>) => {
    console.log("Running a firestore batch delete...", refs);
    let batch: firebase.firestore.WriteBatch = null;
    let index = 0;
    for (let key of refs) {
      if (index === 0) {
        console.log("Creating a new firestore batch");
        batch = db.batch();
      }

      batch.delete(key);

      index += 1;
      if (index === 499) {
        console.log("Commiting batch...");
        await batch.commit();
        console.log("Firestore batch sent.");
        index = 0;
      }
    }

    if (index > 0) {
      console.log("Commiting last batch...");
      await batch.commit();
      console.log("Last firestore batch sent.");
    }
  }, [db]);
  //#endregion

  return (
    <FirestoreContext.Provider
      value={{
        userSettings,
        workspaceSettings,
        updateUserSettings,
        batchUpdate,
        batchDelete,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );

}

export { FirestoreProvider, FirestoreContext };
