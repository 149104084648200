import { IconButton, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export interface RelogsHelpTipProps {
    helpText: string | JSX.Element;
}

export function RelogsHelpTip({ helpText }: RelogsHelpTipProps) {
    return (
        <Tooltip title={helpText}>
            <IconButton size="small">
                <HelpOutlineIcon />
            </IconButton>
        </Tooltip>)
}