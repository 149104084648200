import { FieldProps, UiSchema, WidgetProps } from "@rjsf/core";
import { isString } from "lodash";

export function ResolveFormValue(formProps: FieldProps | WidgetProps) {
    return formProps.value || formProps.formData || formProps.schema?.default || formProps.initialValue;
}

export function FieldToWidgetProps(fieldProps: FieldProps): WidgetProps {
    return {
        id: fieldProps.id,
        schema: fieldProps.schema,
        uiSchema: fieldProps.uiSchema,
        initialValue: fieldProps.initialValue,
        formData: fieldProps.formData,
        value: fieldProps.value,
        required: fieldProps.required,
        disabled: fieldProps.disabled,
        readonly: fieldProps.readonly,
        autofocus: fieldProps.autofocus,
        placeholder: fieldProps.placeholder,
        onChange: fieldProps.onChange,
        options: fieldProps.options,
        formContext: fieldProps.formContext,
        onBlur: fieldProps.onBlur,
        onFocus: fieldProps.onFocus,
        label: fieldProps.label,
        multiple: fieldProps.multiple,
        rawErrors: fieldProps.rawErrors,
        registry: fieldProps.registry
    } as WidgetProps;
}

export function IsWidget(uiSchema: UiSchema) {
    if (!uiSchema) {
        return false;
    }

    const uiWidget = uiSchema["ui:widget"];
    return uiWidget !== null && uiWidget !== undefined;
}

const WidgetMapping: { [widgetName: string]: string } = {
    'hidden': 'HiddenWidget'
}

export function GetWidget(fieldProps: FieldProps, uiSchema: UiSchema, updateProps: (props: WidgetProps) => WidgetProps = null): JSX.Element {
    const { registry } = fieldProps;
    if (!IsWidget(uiSchema)) {
        return null;
    }

    var widgetProps = FieldToWidgetProps(fieldProps);
    if (!!updateProps) {
        widgetProps = updateProps(widgetProps);
    }

    const uiWidget = uiSchema['ui:widget'];
    var Widget = uiWidget;
    if (isString(uiWidget) && uiWidget.length > 0) {
        const widgetName = WidgetMapping[uiWidget] !== undefined
            ? WidgetMapping[uiWidget]
            : uiWidget;

        Widget = registry.widgets[widgetName]
    }

    if (!Widget) {
        return null;
    }

    return <Widget {...widgetProps} />;
}