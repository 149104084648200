import { useGoogleLogin } from "@react-oauth/google";
import { UiSchema, WidgetProps } from "@rjsf/core";
import { Button, Divider, Grid, Paper, Stack, Typography, useTheme } from "@material-ui/core";
import { ResolveFormValue } from "./PipebaseFormShared";

interface GoogleOAuthProps {
    value: any;
    uiSchema: UiSchema;
    errors?: string[];
    developerTokenRequired?: boolean;
    onChange: (value: any) => void;
}

const RESULT_BASE: any = {
    auth_type: 'Client',
    client_id: 'pipebase_client_id',
    client_secret: 'pipebase_client_secret',
    refresh_token: 'pipebase_refresh_token',
    developer_token: 'pipebase_developer_token'
};

function GoogleOAuth({ uiSchema, value, onChange, errors = null, developerTokenRequired = false }: GoogleOAuthProps) {
    const theme = useTheme();
    const uiOptions = !!uiSchema ? uiSchema['ui:options'] : null;
    const scope = !!uiOptions && !!(uiOptions['googleScope']) ? uiOptions['googleScope'] as string : '';

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            console.log(codeResponse);
            var result = { ...RESULT_BASE, access_token: codeResponse.code };
            if (developerTokenRequired) {
                result['developer_token'] = 'pipebase_developer_token'
            }

            onChange(result)
        },
        onError: error => console.error(error),
        flow: 'auth-code',
        select_account: false,
        scope: scope
    });

    let content = null;

    if (!!value && !!value.access_token) {
        content = (
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <Button
                        fullWidth
                        color='secondary'
                        variant="contained"
                        onClick={() => login()}
                    >
                        Re-authenticate
                    </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Button
                        fullWidth
                        color='error'
                        variant="contained"
                        onClick={() => onChange(null)}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        );
    } else {
        content = (
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <Button
                        fullWidth
                        color='secondary'
                        variant="contained"
                        onClick={() => login()}
                    >
                        Authenticate
                    </Button>
                </Grid>
            </Grid>
        );
    }

    if (uiOptions["enableFrame"] != undefined && uiOptions["enableFrame"] === true) {
        return (
            <Paper
                elevation={3}
                sx={{ mx: 2, mt: 2, p: 1, backgroundColor: theme.palette.grey[100] }}
            >
                <Stack sx={{ p: 1 }}>
                    <Typography variant="h5">Authenticate via Google (Preview)</Typography>
                    <Divider sx={{ mb: 2 }} />
                    {content}
                </Stack>
            </Paper>
        );
    }

    return (
        <Stack sx={{ p: 1 }}>
            <Typography variant="h5">Authenticate via Google (Preview)</Typography>
            <Divider sx={{ mb: 2 }} />
            {content}
            {(!!errors && errors.length > 0) &&
                <>
                    {errors.map(err => <Typography color='error'>{err}</Typography>)}
                </>
            }
        </Stack>
    );
}

export function GoogleAuthWidget(props: WidgetProps) {
    const { onChange, uiSchema, schema } = props;
    const value = ResolveFormValue(props);
    const developerTokenRequired = schema.required?.includes('developer_token') ?? false;

    return (<GoogleOAuth onChange={onChange} uiSchema={uiSchema} value={value} errors={props.rawErrors} developerTokenRequired={developerTokenRequired} />);
}