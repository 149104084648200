import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { useState } from "react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


export function PipebasePasswordField(props: TextFieldProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextField
            {...props}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => setShowPassword(s => !s)}
                            onMouseDown={(event) => { event.preventDefault(); }}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}