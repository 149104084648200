import { UiSchema } from "@rjsf/core";
import { RJSFSchema } from "@rjsf/utils";

export const FeedbackFormSchema: RJSFSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "required": [
        "category", "featureCategory", "description", "rate"
    ],
    "properties": {
        "category": {
            "type": "string",
            "title": "Category",
            "default": "General",
            "description": "Feedback category",
            "oneOf": [
                { "const": "General", "title": "General" },
                { "const": "Support", "title": "Support" },
                { "const": "New Feature", "title": "New Feature" },
                { "const": "Data Connection", "title": "Data Connection" }
            ]
        },
        "featureCategory": {
            "type": "string",
            "title": "Feature Category",
            "default": "General",
            "description": "What feature the feadback refer to",
            "oneOf": [
                { "const": "General", "title": "General" },
                { "const": "Query Builder", "title": "Query Builder" },
                { "const": "AI Assistant", "title": "AI Assistant" },
                { "const": "Tables Management", "title": "Tables Management" },
                { "const": "Data Connection", "title": "Data Connection" },
                { "const": "Ingestion", "title": "Ingestion" },
                { "const": "User Management", "title": "User Management" },
                { "const": "Settings", "title": "Settings" }
            ]
        },
        "subject": {
            "type": "string",
            "title": "Subject",
            "description": "Enter subject (optional)",
        },
        "description": {
            "type": "string",
            "title": "Description",
            "description": "Enter description of your feedback",
        },
        "rate": {
            "type": "number",
            "title": "Rate",
            "maximum": 5,
            "minimum": 1
        }
    }
};

export const FeedbackUISchema: UiSchema = {
    "subject": {
        "ui:widget": "text",
        "ui:autofocus": true
    },
    "description": {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 5
        }
    },
    "rate": {
        "ui:widget": "RateWidget"
    }
}