import { WorkspaceCreationStatus } from './../@types/firestore';
import useFirestore from 'hooks/useFirestore';
import NewWorkspaceCreationPage from 'pages/dashboard/NewWorkspaceCreationPage';
import { useState, ReactNode, useMemo, memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function NewCustomerGuard({ children }: AuthGuardProps) {
  const { userSettings, workspaceSettings } = useFirestore();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);


  if ((userSettings && !userSettings.WorkspaceId) || (!!workspaceSettings?.Status && workspaceSettings.Status !== WorkspaceCreationStatus.Completed)) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    console.debug("redirecting to new workspace creation")
    return <NewWorkspaceCreationPage />;
  }

  console.debug("skipped new workspace creation", userSettings, workspaceSettings);

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
