import { RJSFSchema } from "@rjsf/utils";
import Form from '@rjsf/material-ui';
import { Button } from "@material-ui/core";
import { UiSchema } from "@rjsf/core";
import { RelogsFiedsRegistry, RelogsWidgetsRegistry } from "./PipebaseFormRegistry";

export interface RelogsJsonFormProps {
    schema: RJSFSchema;
    liveValidate?: boolean;
    uiSchema?: UiSchema;
    formData?: any;
    submitButton?: JSX.Element | string;
    onSubmit?: (any) => void;
}

function SubmitButton({ submitButton }: { submitButton: JSX.Element | string }) {
    if (submitButton === null || typeof submitButton === 'string') {
        return (
            <Button type="submit" variant="contained" color="primary">
                {!submitButton ? 'Submit' : submitButton}
            </Button>
        );
    }

    return submitButton;
}

function RelogsJsonForm({ schema, uiSchema = undefined, formData = {}, liveValidate = false, onSubmit = null, submitButton = null }: RelogsJsonFormProps) {
    return (
        <Form
            noHtml5Validate
            schema={schema}
            showErrorList={false}
            liveValidate={liveValidate}
            onSubmit={onSubmit}
            fields={RelogsFiedsRegistry}
            widgets={RelogsWidgetsRegistry}
            uiSchema={uiSchema}
            formData={formData}
        >
            <SubmitButton submitButton={submitButton} />
        </Form>
    );
}

export default RelogsJsonForm;