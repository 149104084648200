import { Box, Button, Dialog, Stack } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import { FeedbackFormSchema, FeedbackUISchema } from "./FeedbackFormSchema";
import RelogsJsonForm from "../common/forms/RelogsJsonForm";
import moment from "moment";
import useFirestore from "hooks/useFirestore";

export interface FeedbackPopupProps {
    isOpen: boolean;
    onClose?: () => void;
}

export function FeedbackPopup({ isOpen, onClose }: FeedbackPopupProps) {
    const { db } = useAuth();
    const { userSettings } = useFirestore();

    const handleOnSubmit = async (formData: any) => {
        console.log(formData);
        formData.Timestamp = moment().format();
        formData.UserId = userSettings?.Email;
        formData.WorkspaceId = userSettings?.WorkspaceId;

        await db.collection('Feedbacks').add(formData);
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <Stack spacing={2} sx={{ p: 3, width: 1 }}>
                <Typography width={1} align="center" variant="h4">Give us feedback!</Typography>
                <RelogsJsonForm
                    schema={FeedbackFormSchema}
                    uiSchema={FeedbackUISchema}
                    onSubmit={(e) => handleOnSubmit(e.formData)}
                    submitButton={
                        <Box display='flex' justifyContent='center' alignContent='center' sx={{ width: 1 }}>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Box>
                    }
                />
            </Stack>
        </Dialog>
    );
}