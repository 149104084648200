import { Box, Grid, Rating, Switch, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { FieldProps, WidgetProps } from "@rjsf/core";
import { HTMLAttributes, useEffect, useState } from "react";
import { RelogsAutocomplete, RelogsAutocompleteOption } from "../RelogsAutocomplete";
import { JSONSchema7, JSONSchema7Type } from 'json-schema'
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import { GetWidget, IsWidget, ResolveFormValue } from "./PipebaseFormShared";
import { PipebaseFieldFormatMap } from "./PipebaseFormFieldsEx";
import { PipebasePasswordField } from "../PipebasePasswordField";

export const FORM_SUPPORTED_WIDGETS_TYPES = ["RateWidget", "date-time", "date"];

class RelogsFormSelectOption implements RelogsAutocompleteOption {
    Title: string;
    Value: JSONSchema7Type;
    Order: number;

    constructor(
        title: string,
        value: JSONSchema7Type,
        order: number
    ) {
        this.Title = title;
        this.Value = value;
        this.Order = order;
    }

    getLabel(): string { return this.Title; }
    getGroup(): string { return undefined; }
    getDisplayView(props: HTMLAttributes<HTMLLIElement>, selected: boolean): JSX.Element { return (<>{this.Title}</>); }
    getId(): string { return `${this.Value}`; }
}

export interface RelogsBaseFieldFormProps extends FieldProps<any> {
    initialValue: JSONSchema7Type;
    inputConvert: (inputValue: string) => JSONSchema7Type;
}



function RelogsMultiSelectFieldForm({ id, required, schema, onChange }: RelogsBaseFieldFormProps) {
    let options: RelogsFormSelectOption[] = [];
    if (schema.oneOf) {
        for (let i in schema.oneOf) {
            const o = schema.oneOf[i] as JSONSchema7;
            if (!o) {
                continue;
            }

            options.push(new RelogsFormSelectOption(o.title, o.const, +i));
        }
    }

    const handleOnChange = (values: RelogsFormSelectOption[] | RelogsFormSelectOption) => {
        if (schema.oneOf) {
            onChange((values as RelogsFormSelectOption).Value);
        }

        if (schema.anyOf) {
            onChange((values as RelogsFormSelectOption[]).map(v => v.Value));
        }
    }

    const defaultValue = schema?.default
        ? options.find(o => o.Value === schema.default)
        : undefined;

    return (
        <RelogsAutocomplete
            id={id}
            placeholder={schema?.title}
            label={schema?.title}
            multiple={!!schema?.anyOf}
            disableClearable={required}
            required={required}
            disableCloseOnSelect={!!schema?.anyOf}
            defaultValue={defaultValue}
            options={options}
            onChange={handleOnChange}
        />
    );
}

function PipebaseConstFieldForm({ name, constValue, onChange }: { id: string, name: string, constValue: any, onChange: (value: any) => void }) {
    useEffect(() => {
        onChange(constValue)
    }, [constValue])

    return (
        <Grid container spacing={1}>
            <Grid item xs="auto" md="auto">
                <Typography variant="subtitle2" component="p" color="textSecondary">{name}:</Typography>
            </Grid>
            <Grid item xs="auto" md="auto">
                <Typography variant="subtitle2" component="p" >{constValue}</Typography>
            </Grid>
        </Grid>
    );
}

function RelogsBaseFieldForm(fieldProps: RelogsBaseFieldFormProps) {
    const { id, required, schema, value, name, onChange, rawErrors, initialValue, inputConvert, uiSchema } = fieldProps;
    const [fieldValue, setFieldValue] = useState<JSONSchema7Type>(undefined);
    const isConst = !!schema?.const;
    const handleOnChange = (event) => {
        const eventValue = inputConvert(event.target.value);
        onChange(eventValue);
        setFieldValue(eventValue);
    }

    useEffect(() => {
        if (isConst || IsWidget(uiSchema)) {
            return;
        }

        if (!!fieldValue || (!schema?.default && !fieldProps?.formData)) {
            return;
        }

        const v = ResolveFormValue(fieldProps);
        const initValue = inputConvert(v);
        onChange(initValue);
        setFieldValue(initValue)
    }, [schema, value, initialValue]);

    const widget = GetWidget(fieldProps, fieldProps.uiSchema);
    if (!!widget) {
        return widget;
    }

    if (isConst) {
        return (<PipebaseConstFieldForm id={id} name={schema.title ?? name} constValue={schema.const} onChange={onChange} />);
    }

    const rows = uiSchema["ui:options"]?.rows as number ?? 1;
    const textFieldProps: TextFieldProps = {
        id: id,
        error: (!!rawErrors && rawErrors.length),
        // to add a "*" in the label
        required: required,
        // null makes the component uncontrolled
        value: fieldValue,
        // avoid the case of empty string considered as a value
        // and not trigerring required validation
        onChange: handleOnChange,
        type: schema.format,
        size: "small",
        fullWidth: true,
        autoFocus: uiSchema && uiSchema["ui:autofocus"],
        multiline: rows > 1,
        rows: rows,
        InputLabelProps: { shrink: !!fieldValue }
    };

    if (schema.format === 'password') {
        return <PipebasePasswordField {...textFieldProps} />
    }

    return <TextField {...textFieldProps} />;
}

export function RelogsFormStringField(fieldProps: FieldProps) {
    if (fieldProps?.schema?.oneOf) {
        return (
            <RelogsMultiSelectFieldForm
                {...fieldProps}
                initialValue={""}
                inputConvert={(v) => v || null}
            />
        )
    }

    const schemaFormat = fieldProps?.schema?.format;
    if (PipebaseFieldFormatMap[schemaFormat] !== undefined) {
        const FormattedField = PipebaseFieldFormatMap[schemaFormat];
        return <FormattedField {...fieldProps} />;
    }

    if (!!fieldProps.schema && (schemaFormat === undefined || schemaFormat === null) && !!fieldProps.schema['airbyte_secret']) {
        fieldProps.schema.format = "password";
    }

    return (
        <RelogsBaseFieldForm
            {...fieldProps}
            initialValue={""}
            inputConvert={(v) => v || null}
        />
    );
}

export function RelogsFormNumberField(fieldProps: FieldProps) {
    return (
        <RelogsBaseFieldForm
            {...fieldProps}
            initialValue={0}
            inputConvert={(v) => +v}
        />
    );
}

export function RelogsFormBooleanField({ schema, onChange }: FieldProps) {
    const [isEnabled, setIsEnabled] = useState<boolean>((schema.default as boolean) ?? false);

    const handleOnChange = (v: boolean) => {
        onChange(v);
        setIsEnabled(v);
    }

    return (
        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                    <Switch
                        name={schema.title}
                        checked={isEnabled}
                        onChange={(e, v) => handleOnChange(v)}
                    />
                </Grid>
                <Grid item>{schema.title}</Grid>
            </Grid>
        </Typography>
    );
}

export function RateWidget({ onChange }: WidgetProps) {
    return (
        <Box display='flex' justifyContent='center' alignContent='center' sx={{ width: 1 }}>
            <Rating
                onChange={
                    (_, newValue) => {
                        if (onChange) {
                            onChange(newValue);
                        }
                    }
                }
            />
        </Box>
    );
};

export function PipebaseSchemaField(fieldProps: FieldProps) {
    const widget = GetWidget(fieldProps, fieldProps.uiSchema);
    if (!!widget) {
        return widget;
    }

    return (<SchemaField {...fieldProps} />);
}