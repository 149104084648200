import firebase from 'firebase/app';

/** Field value for write with `firebase.firestore.FieldValue.serverTimestamp()`
Timestamp when reading the data */
type FirestoreTimestamp = firebase.firestore.Timestamp;

export type UserStatus = 'Pending' | 'Active';

export type UserSettings = {
  Email: string;
  CreatedAt: FirestoreTimestamp;
  UpdatedAt: FirestoreTimestamp;
  WorkspaceId: string;
  Name: string;
  PhotoUrl: string;
  Status: UserStatus;
} | null;

export type IngestionKey = {
  Id: string;
  Key: string;
  State: string;
  Tables: string[];
  CreatedAt: FirestoreTimestamp;
  UpdatedAt: FirestoreTimestamp;
} | null;

export enum WorkspaceCreationStatus {
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed"
};

export type WorkspaceSettings = {
  Id: string;
  Name: string;
  CreatedAt: FirestoreTimestamp;
  UpdatedAt: FirestoreTimestamp;
  Status: WorkspaceCreationStatus;
  IngestionKeys: IngestionKey[];
} | null;

export type FirestoreContextProps = {
  userSettings: UserSettings,
  workspaceSettings: WorkspaceSettings,
  updateUserSettings: (data: Partial<UserSettings>) => Promise<void>;
  batchUpdate: (refAndUpdates: Map<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, Object>) => Promise<void>;
  batchDelete: (refs: Array<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>) => Promise<void>;
};
