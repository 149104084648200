// routes
import { getFeaturesInfoArray } from 'components/pipebase-io/common/FeaturesInfo';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

const sidebarConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.general.home,
        icon: <SvgIconStyle src={`/static/icons/navbar/ic_kanban.svg`} sx={{ width: '100%', height: '100%' }} />
      },
      ...getFeaturesInfoArray().filter(f => f.public).map(f => {
        return {
          title: f.title,
          path: f.path,
          icon: f.icon
        }
      })
    ]
  }
];

export default sidebarConfig;
