// material
import { styled } from '@material-ui/core/styles';
import { Button, Container, Typography, Card, Stack, Paper } from '@material-ui/core';
// layouts
// routes
// components
import Page from '../../components/Page';
import Logo from 'components/Logo';
import { useSnackbar } from 'notistack5';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));


// ----------------------------------------------------------------------

export default function VerifyCode() {
  const { sendVerificationCode, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    sendVerificationCode(() => { });
  }, [sendVerificationCode]);

  const onSendVerificationClicked = () => {
    enqueueSnackbar('Verification code sended', { variant: 'info' });
  };

  return (
    <RootStyle title="Login">
      <Container maxWidth="sm">
        <ContentStyle>
          <Card sx={{ p: 2 }}>
            <Stack spacing={1} alignItems='center' justifyItems='center' sx={{ width: 1, mb: 2, mt: 1 }}>
              <Logo variant='logo-sideway' sx={{ width: 0.8 }} />
              <Typography width={1} textAlign='center' variant='subtitle1'>
                <Button onClick={logout}>
                  Back to Login
                </Button>
              </Typography>
            </Stack>
            <Paper sx={{ backgroundColor: '#f2f8ff', color: 'primary.darker', textAlign: 'center', p: 2 }}>
              <Typography textAlign='center' width={1} variant='h6'>Please verify your email</Typography>
            </Paper>
            <Typography variant="body2" align="center">
              Don't have a code? &nbsp;
              <Button onClick={onSendVerificationClicked}>
                Resend code
              </Button>
            </Typography>
          </Card >
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
