import { IconButton } from "@material-ui/core";
import { FeedbackPopup } from "./FeedbackPopup";
import { useState } from "react";

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

export interface FeedbackButtonProps { }

export function FeedbackButton({ }: FeedbackButtonProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <FeedbackPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
            <IconButton
                color='info'
                size='large'
                onClick={() => setIsOpen(true)}
            >
                <RecordVoiceOverIcon />
            </IconButton>
        </>
    );
}