import { Avatar, IconButton, IconButtonProps, useTheme } from "@material-ui/core";

export type ButtonSubColors = 'main' | 'dark' | 'light';
export type ButtonColors = 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export interface CircleIconButtonProps extends IconButtonProps {
    color: ButtonColors;
    colorVariant?: ButtonSubColors;
    icon: JSX.Element;
}

export function CircleIconButton({ color, icon, colorVariant = 'main', ...iconButtonProps }: CircleIconButtonProps) {
    const theme = useTheme();
    const actualColor = theme.palette[color][colorVariant];

    return (
        <IconButton {...iconButtonProps}>
            <Avatar sx={{ backgroundColor: actualColor, color: theme.palette.background.paper }} >
                {icon}
            </Avatar>
        </IconButton>
    );
}