import { Box, Dialog, Divider, Grid, Stack, Switch } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import UploadSingleFile from "./UploadSingleFile";
import { DropEvent, FileRejection } from "react-dropzone";
import { useState } from "react";
import useRelogsDataPlane from "hooks/useRelogsDataPlane";
import { useSnackbar } from "notistack5";
import { RelogsHelpTip } from "../RelogsHelpTip";
import { PipebaseCreateOrSelectTable } from "components/pipebase-io/tables-management/PipebaseCreateOrSelectTable";
import usePipebaseWorkspace from "hooks/usePipebaseWorkspace";
import { LoadingButton } from "@material-ui/lab";

export interface UploadFilePopupProps {
    isOpen: boolean;
    onClose?: () => void;
}

export function UploadFilePopup({ isOpen, onClose }: UploadFilePopupProps) {
    const { directIngest } = useRelogsDataPlane();
    const { refresh } = usePipebaseWorkspace();
    const { enqueueSnackbar } = useSnackbar();

    const [tableName, setTableName] = useState<string>(null);
    const [folderName, setFolderName] = useState<string>(null);
    const [tableError, setTableError] = useState<boolean>(false);
    const [fileError, setFileError] = useState<boolean>(false);
    const [ingestErrors, setIngestErrors] = useState<string[]>([]);
    const [expand, setExpand] = useState<boolean>(true);
    const [headersIncluded, setHeadersIncluded] = useState<boolean>(true);
    const [file, setFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const uploadFile = async () => {
        let ingestErrors = [];
        if (!tableName || !folderName) {
            ingestErrors.push('Missing table info');
            setTableError(true);
        }

        if (!file) {
            ingestErrors.push('No file selected');
            setFileError(true);
        }

        if (!!ingestErrors && ingestErrors.length > 0) {
            setIngestErrors(ingestErrors);
            return;
        }

        setIsLoading(true);

        const reader = new FileReader();
        reader.onabort = () => console.log('File reading was aborted');
        reader.onerror = () => console.error('Failed to read file');
        reader.onload = async () => {
            try {
                const binaryStr = reader.result;
                const ingestResult = await directIngest(folderName, tableName, binaryStr, file.type, expand, headersIncluded);
                if ((ingestResult.errorCode ?? -1) > 0) {
                    const errorMessage = !ingestResult.details ? 'Unknown error' : ingestResult.details;
                    setIngestErrors([errorMessage]);
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                    return;
                }

                setIngestErrors([]);
                enqueueSnackbar('Upload completed successfuly', { variant: 'success' });
                setFolderName(null);
                setTableName(null);
                setFile(null);
                onClose();
                await refresh();
            }
            catch (error) {
                setIngestErrors([error?.message]);
                enqueueSnackbar(error?.message, { variant: 'error' });
            }
            finally {
                setIsLoading(false);
            }
        };

        reader.readAsArrayBuffer(file);
    }

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (!acceptedFiles || acceptedFiles.length == 0) {
            setIngestErrors([...ingestErrors, 'No file selected']);
            setFile(null);
            setFileError(true);
        }

        setFile(acceptedFiles[0]);
        setFileError(false);
        setIngestErrors([]);
    };

    const alignmentSx = { display: 'flex', justifyItems: 'center', alignItems: 'center' };

    const onFolderChanged = (newFolder: string, isNewValue: boolean) => {
        setFolderName(newFolder);
        setTableError(false);
        setIngestErrors([]);
    }

    const onTableChanged = (newTable: string, isNewValue: boolean) => {
        setTableName(newTable);
        setTableError(false);
        setIngestErrors([]);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <Stack spacing={1} sx={{ p: 3, width: 1 }}>
                <Typography width={1} align="center" variant="h4">Upload file</Typography>
                <PipebaseCreateOrSelectTable fullWidth hasError={tableError} onFolderChanged={onFolderChanged} onTableChanged={onTableChanged} />
                <Grid container>
                    <Grid item xs={3} md={3} sx={alignmentSx}>
                        <Typography sx={alignmentSx}>Headers</Typography>
                        <RelogsHelpTip helpText="Is first line in file is headers" />
                        <Switch name="Headers" checked={headersIncluded} onChange={(e, v) => setHeadersIncluded(v)} />
                    </Grid>
                    <Grid item xs={9} md={1} sx={{ display: { xl: 'none', xs: 'block' } }} />
                    <Grid item xs="auto" sx={{ ...alignmentSx, mx: 3, display: { xl: 'flex', xs: 'none' } }}>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={3} md={3} sx={alignmentSx}>
                        <Typography sx={alignmentSx}>Expand&nbsp;&nbsp;</Typography>
                        <RelogsHelpTip helpText="Expand object to multiple columns, if disabled all data will be inserted to single dynamic column" />
                        <Switch name="Expand" checked={expand} onChange={(e, v) => setExpand(v)} />
                    </Grid>
                </Grid>
                <UploadSingleFile file={file} onDrop={onDrop} error={fileError} />
                {!!ingestErrors &&
                    ingestErrors.map(err => {
                        return <Typography width={1} textAlign='center' variant="body2" color='error'>{err}</Typography>
                    })
                }

                <Box width={1} display='flex' justifyContent='center' alignContent='center'>
                    <LoadingButton loading={isLoading} onClick={uploadFile} variant="contained" sx={{ width: 0.4 }}>
                        Upload
                    </LoadingButton>
                </Box>
            </Stack>
        </Dialog>
    );
}