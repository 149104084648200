import { Box, Button, Typography, TextField, Stack, LinearProgress } from '@material-ui/core';

// material
import { styled } from '@material-ui/core/styles';
import Logo from 'components/Logo';
import useRelogsControlPlane from 'hooks/useRelogsControlPlane';
import { useState } from 'react';
// components

// hooks
import useAuth from 'hooks/useAuth';
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from '@material-ui/lab';
import useFirestore from 'hooks/useFirestore';
import { WorkspaceCreationStatus } from './../../../@types/firestore';
import { sleep } from 'utils/pipebase-io/utils';

// ----------------------------------------------------------------------




const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

export default function WorkspaceCreationContent() {
    const { logout } = useAuth();
    const { updateUserSettings, workspaceSettings, userSettings } = useFirestore();
    const { createWorkspace } = useRelogsControlPlane();
    const { handleSubmit, control, watch, formState: { isSubmitting, isSubmitSuccessful, isSubmitted, isValid } } = useForm({ mode: 'all' });

    const [creationStatus, setCreationStatus] = useState<string>(null);
    const [creationError, setCreationError] = useState<string>(null);

    const [operationId, setOperationId] = useState<string>(null); // todo store in db
    const watchAllFields = watch();

    const onSubmit = async (data) => {
        console.debug("Form data:", data);
        setCreationStatus("Sending creation request...");
        const result = await createWorkspace(data["workspaceName"]);
        console.log("create workspace result: ", result);
        const operationId = (result as any)?.data?.operationId;
        const workspaceId = (result as any)?.data?.workspaceId;
        console.log("operation id: ", operationId);
        console.log("workspace id: ", workspaceId);

        setOperationId(operationId);

        if (!workspaceId) {
            throw new Error("Unexpected workspace ID received from server");
        }

        setCreationStatus("Updating user settings...");
        await updateUserSettings({ WorkspaceId: workspaceId });
        setCreationStatus("Waiting for workspace to be ready...");
        await sleep(5000); // Sleeping a bit, to give some time for the workspace document to be fetched
    };

    const onError = (error) => {
        console.error("Error when submitting the form", error);
        setCreationError(error.toString());
    };

    const handleLogout = async () => {
        await logout?.();
    };

    const userHasWorkspaceId = (!!userSettings?.WorkspaceId);
    const defaultWorkspaceCreationIsInProgress = (workspaceSettings?.Status === WorkspaceCreationStatus.InProgress);
    const defaultWorkspaceCreationFailed = (workspaceSettings?.Status === WorkspaceCreationStatus.Failed);

    const failedCreatingWorkspace = (isSubmitted && !isSubmitSuccessful) || defaultWorkspaceCreationFailed;

    const workspaceName = watchAllFields.workspaceName ?? workspaceSettings?.Name ?? '';

    if (failedCreatingWorkspace) {
        return (
            // todo include if exists: operationid, workspace id
            <>
                <Box>
                    <Box display='flex' alignItems='center' justifyItems='center' sx={{ width: 1, my: 2.5 }}>
                        <Logo variant='logo-sideway' />
                    </Box>
                    <Typography variant="h5" justifyContent="center" display="flex" sx={{ mt: 1 }}>{`Failed creating workspace`}</Typography>
                    {
                        workspaceName &&
                        <Typography variant="h6" color="primary" justifyContent="center" display="flex">{` ${workspaceName}`}</Typography>
                    }
                    {
                        userSettings?.WorkspaceId &&
                        <Typography variant="body2" color="deepskyblue" justifyContent="center" display="flex">{`workspace id: ${userSettings?.WorkspaceId}`}</Typography>
                    }
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 3 }} justifyContent="center" display="flex">We are sorry about that, contact us for support</Typography>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        rel="noopener noreferrer"
                        href={`mailto:support@pipebase.io`}
                        sx={{ mt: 1 }}
                    >
                        Mail to support@pipebase.io
                    </Button>
                    {
                        creationError &&
                        <ErrorStyle sx={{ mt: 1 }}>
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {"Error:"}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}
                                    style={{ whiteSpace: 'pre-line' }} // for multi lines typography
                                >
                                    {creationError}
                                </Typography>
                            </Box>
                        </ErrorStyle>
                    }
                </Box>
            </>
        )
    }
    else if (!userHasWorkspaceId && !isSubmitting) {
        return (<>
            <Box display='flex' alignItems='center' justifyItems='center' sx={{ width: 1, my: 2.5 }}>
                <Logo variant='logo-sideway' />
            </Box>
            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                To get started, you need to create a new workspace. Just pick a name, you can change it later:
            </Typography>

            <form onSubmit={() => handleSubmit(onSubmit)().catch((e) => onError(e))}>
                <Stack spacing={2} display='flex' alignItems='center' justifyItems='center' width={1}>

                    <Controller
                        name="workspaceName"
                        control={control}
                        rules={{ required: true, maxLength: 35, pattern: /^[0-9a-zA-Z-_]+$/ }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                fullWidth
                                required
                                size='small'
                                {...field}
                                label="Workspace name"
                                error={Boolean(error)}
                                helperText={Boolean(error) && ((error?.type === "required" && "Workspace name is required")
                                    || (error?.type === "maxLength" && "Max length reached")
                                    || (error?.type === "pattern" && "Can include only letters, digits, - and _")
                                    || ("Error: " + JSON.stringify(error)))}
                            />
                        )} />
                    <LoadingButton
                        fullWidth
                        color="info"
                        size="medium"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isValid}
                    >
                        Create workspace
                    </LoadingButton>
                    <LoadingButton
                        fullWidth
                        sx={{ mt: 2 }}
                        color="secondary"
                        loading={false}
                        disabled={false}
                        onClick={handleLogout}
                        variant="text"
                    >
                        logout
                    </LoadingButton>
                </Stack >

            </form>
        </>
        )
    } else if (isSubmitting || (userHasWorkspaceId && defaultWorkspaceCreationIsInProgress)) {
        return (
            <Stack sx={{ my: 2, mx: 2 }}>
                <Box display='flex' alignItems='center' justifyItems='center' sx={{ width: 1, my: 2.5 }}>
                    <Logo variant='logo-sideway' />
                </Box>
                <Typography variant="h5" textAlign='center' sx={{ mt: 1 }}>Creating your workspace</Typography>
                <Typography variant="h6" color="primary" textAlign='center'>{` ${!!workspaceName ? workspaceName : 'N/A'}`}</Typography>
                <Typography variant="body2" color="deepskyblue" textAlign='center'>{`workspace id: ${!(userSettings?.WorkspaceId) ? 'N/A' : userSettings?.WorkspaceId}`}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} textAlign='center'>{!creationStatus ? 'In Progress' : creationStatus}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }} textAlign='center'>Hang on, it can take around 30 seconds</Typography>
                <LinearProgress sx={{ my: 1 }} />
                {operationId &&
                    <Typography variant="body2" sx={{ color: 'lig' }} textAlign='center'>{`Operation Id: ${!operationId ? 'N/A' : operationId}`}</Typography>
                }
            </Stack>
        );
    }

    return null;
}
